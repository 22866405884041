import React from 'react';
import { Button } from 'react-bootstrap';
import ContentImage1 from '~images/content-image1.png';
import ContentImage2 from '~images/content-image2.png';
import ContentImage3 from '~images/content-image3.png';
import ContentImage4 from '~images/content-image4.png';
import ContentImage5 from '~images/content-image5.png';
import { StepDiv } from './styles';

export const Instructions: React.FC = () => {
  const [activeStep, setActiveStep] = React.useState(1);

  const handleInstructionButtonClick = (type: 'next' | 'previous') => () => {
    const value = type === 'next' ? activeStep + 1 : activeStep - 1;
    setActiveStep(value);
  };

  const StepOne = () => (
    <StepDiv className={activeStep === 1 ? 'show' : ''}>
      <span>
        <b>Step 1:</b>
        <br></br> Navigate to the IAM page and click on Users tab. Click “Create user” button on top right and give your
        user a distinguishable name.
      </span>
      <span>
        <b>Step 2:</b> <br></br>Click next. On the permissions page select the “Attach policies directly” option, and
        click on “Create policy button”.
      </span>
      <img src={ContentImage1} alt="Content Image Instruction" />
    </StepDiv>
  );
  const StepTwo = () => (
    <StepDiv className={activeStep === 2 ? 'show' : ''}>
      <span>
        Step 3:
        <br />
        Once new window has opened to create policy page, select S3 in the services dropdown.
        <br />
        For actions allowed, select "All read actions" AND "ListBucket action" in the List access level.
      </span>
      <img src={ContentImage2} alt="Content Image Instruction" />
    </StepDiv>
  );
  const StepThree = () => (
    <StepDiv className={activeStep === 3 ? 'show' : ''}>
      <span>
        Step 4:
        <br />
        Below in the Resources panel, select "Specific" and add the bucket arn you wish to give access to in the{' '}
        <b>bucket</b> and <b>object</b> sections (for object make sure you also include the wildcard "/*")
      </span>
      <img src={ContentImage5} alt="Content Image Instruction" />
    </StepDiv>
  );
  const StepFour = () => (
    <StepDiv className={activeStep === 4 ? 'show' : ''}>
      <span>
        <b>Step 5:</b>
        <br></br>Create your policy and give it a unique name. Once created navigate back to the previous create user
        tab, look for the policy you just created and attach it (you may have to press the refresh policies icon on the
        top right of the pane to be able to see your newly created policy).
      </span>
      <img src={ContentImage3} alt="Content Image Instruction" />
    </StepDiv>
  );
  const StepFive = () => (
    <StepDiv className={activeStep === 5 ? 'show' : ''}>
      <span>
        <b>Step 6:</b>
        <br></br>Once created click on the created user and navigate to the Security Credentials tab. Scroll down and in
        the Access Key section click “Create access key”.
      </span>
      <span>
        For Use Case select “Command Line interface”. Apply tags if need be. Once you click create make sure on next
        screen your copy and paste the Access key and Secret access key below.
      </span>
      <img src={ContentImage4} alt="Content Image Instruction" />
    </StepDiv>
  );

  return (
    <div className="instructionDiv">
      <h4>Instructions</h4>
      <div className="slide">
        <StepOne />
        <StepTwo />
        <StepThree />
        <StepFour />
        <StepFive />

        <div className="modal-buttons">
          <Button onClick={handleInstructionButtonClick('previous')} disabled={activeStep <= 1}>
            Previous
          </Button>
          <Button onClick={handleInstructionButtonClick('next')} disabled={activeStep >= 5}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};
