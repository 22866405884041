import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import PreviewBg from '~images/preview_bg.png';
import { HideScrollbars } from '~styles/mixins';
import { InputSection } from '~components/inputs/styles';

export const CanvasSection = styled.section`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 1rem 0px rgba(0, 0, 0, 0.2) inset;
  background: url(${PreviewBg}) repeat;
  padding: 1.5rem;
  overflow-y: scroll;

  ${HideScrollbars}
`;

export const TopHeader = styled.header`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  height: 2rem;

  h2 {
    font-family: EavesRegular;
    color: ${({ theme }) => theme.palette.platinum};
    margin: 0;
    font-size: 0.9rem;
    text-transform: uppercase;
  }

  span {
    position: absolute;
    width: 1.1rem;
    right: 3rem;
    cursor: pointer;
    margin-bottom: 0.25rem;

    &:hover {
      svg path {
        fill: ${({ theme }) => theme.palette.red};
      }
    }

    &.handle {
      width: 2.5rem;
      right: 0;
      cursor: grabbing;

      &:hover {
        svg path {
          fill: ${({ theme }) => theme.palette.primaryPlum};
        }
      }
    }

    svg {
      width: 100%;

      path {
        fill: ${({ theme }) => theme.palette.platinum};
      }
    }
  }
`;

interface ImageDivProps {
  url: string;
}

export const ImageDiv = styled.div<ImageDivProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.platinum};
  background: url('${({ url }) => url}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: ${({ url }) => (url ? 50 : 0)}%;
  margin-bottom: 0.5rem;

  p {
    position: absolute;
    display: flex;
    align-items: center;
    color: white;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1.25rem;
    background: ${rgba('black', 0.3)};
    font-size: 0.9rem;
    padding: 0.2rem;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
  }

  section {
    display: none;
    align-items: center;
    justify-content: center;
    width: calc(100% - 5rem);
    height: 7rem;
    color: ${({ theme }) => theme.palette.platinum};
    text-transform: uppercase;
    border: 1px dashed ${({ theme }) => rgba(theme.palette.platinum, 0.25)};
    margin: 2rem 0;

    span {
      display: inline-block;
      width: 0.8rem;

      svg {
        width: 100%;

        path {
          fill: ${({ theme }) => theme.palette.platinum};
        }
      }
    }
  }
`;

interface CarouselItemArticleProps {
  hasItem: boolean;
}

export const CarouselItemArticle = styled.article<CarouselItemArticleProps>`
  position: relative;
  display: block;
  width: 100%;
  padding: 0.1rem 1.2rem 0.5rem;
  border: 1px solid ${({ theme, hasItem }) => rgba(theme.palette.platinum, hasItem ? 1 : 0.25)};
  background: ${({ theme, hasItem }) => rgba(theme.palette.darkenedWhite, hasItem ? 1 : 0.25)};
  margin-bottom: 1rem;

  ${({ hasItem }) =>
    !hasItem &&
    css`
      ${TopHeader} {
        opacity: 0.25;
        span {
          display: none;
        }
      }

      ${ImageDiv} {
        border-color: ${({ theme }) => rgba(theme.palette.platinum, 0.25)};

        p {
          display: none;
        }

        section {
          display: flex;
        }
      }

      ${InputSection} {
        opacity: 0.25;
      }
    `}
`;
