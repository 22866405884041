import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import { InputSection } from '~components/inputs/styles';
import { StyledInputSection as ChipFieldInputSection } from '~components/inputs/chip-field/styles';
import { StyledDropdown } from '~components/select/styles';
import { breakpoints } from '~styles/mixins';

interface RuleArticleProps {
  minified?: boolean;
}

export const RuleArticle = styled.article<RuleArticleProps>`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: ${({ minified }) => (minified ? 'column' : 'row')};
  background: ${({ theme, minified }) => (minified ? 'white' : lighten(0.09, theme.palette.lightGrey))};
  box-shadow: ${({ minified }) => (minified ? '0px 0px 8px 0px rgb(2 3 2 / 13%)' : 'none')};
  text-transform: uppercase;
  padding: 0.5rem;
  margin-bottom: 0.5rem;

  &.withNote {
    padding-top: 1.1rem;
  }
  h6.note {
    color: ${({ theme }) => theme.palette.red};
    position: absolute;
    width: 100%;
    text-align: center;
    top: 0.2rem;
    font-size: 0.7rem;
  }

  .header {
    display: flex;
    justify-content: flex-start;
    width: 25%;

    svg.rule-icon {
      width: 1rem;
      max-height: 2rem;
      margin: 0 0.5rem;

      path {
        fill: ${({ theme }) => theme.palette.textGrey};
      }

      &.customerIds,
      &.deviceLanguage {
        width: 1.5rem;
      }
    }

    h1 {
      display: inline-block;
      font-size: 1rem;
      font-family: EavesBold;
      margin: 0 0 0 0.5rem;
      transform: translateY(0.1rem);
      color: ${({ theme }) => theme.palette.textGrey};
      min-width: 100px;
      line-height: 1rem;
    }
  }

  p {
    color: ${({ theme }) => theme.palette.textLightGrey};
    font-size: 0.9rem;
    line-height: 0.9rem;
    margin: 0 5px;
    width: 45%;
  }

  .inputs {
    display: flex;

    .inputs-wrapper{
      display: flex;

      @media ${breakpoints.lg} {
        flex-direction: column;
        gap: 0.5rem;
        justify-content: center:
        align-items: center;
      }
    }

    &__operator {
      width: 15rem;
      margin-right: 0.3rem;

      @media ${breakpoints.lg} {
        margin-right: 0;}
    }

    &__value {
      width: 15rem;
      margin-left: 0.3rem;

      @media ${breakpoints.lg} {
        margin-left: 0;}

      span {
        overflow-x: scroll;
      }

      .notificationSearch {
        max-height: 1.5rem;
        margin-bottom: 0.5rem;

        span {
          overflow: hidden;
        }
      }
    }
  }

  ${({ minified }) =>
    minified &&
    css`
      margin-bottom: 2rem;

      .header {
        justify-content: space-between;
        width: 100%;
        background: ${({ theme }) => lighten(0.09, theme.palette.lightGrey)};
        padding: 0.25rem 0.75rem;

        h1 {
          width: unset;
        }
      }

      .inputs {
        width: 100%;

        .inputs-wrapper {
          width: 100%;
        }

        &__operator,
        &__value {
          width: calc(50% - 1rem);

          ${InputSection} input, ${StyledDropdown} button, & > div {
            min-height: unset;
          }

          ${ChipFieldInputSection} {
            min-height: unset;
          }

          .react-datetimerange-picker {
            height: unset;
          }

          .react-datetime-picker {
            .react-datetime-picker__wrapper {
              padding-top: 0.15rem;
            }
          }
        }
      }

      p {
        width: 100%;
        text-align: center;
        font-size: 0.8rem;
        margin: 0.4rem 0;
      }
    `}
`;

export const ActionsSection = styled.section`
  display: flex;
  align-items: center;
  padding: 0 0 0 0.75rem;

  svg {
    width: 1rem;
    max-height: 2rem;
    margin-right: 0.5rem;

    path {
      fill: ${({ theme }) => theme.palette.textGrey};
    }

    &:hover {
      cursor: pointer;

      path {
        fill: ${({ theme }) => theme.palette.textColor};
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;
