import React, { useState, useEffect } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { useStoreActions, useStoreState } from '~store/hooks';
import DocIcon from '~images/document.svg';
import FolderIcon from '~images/folder.svg';
import CaretIcon from '~images/caret-right.svg';
import { Loader } from '~components/loader';
import { ApiContentProviderItem, GetContentProviderItemsMetadata } from '~api/content-items/types';
import { ProviderItemListDiv, ListRowDiv } from '../styles';
import { Checkbox } from '~components/checkbox';
import { ProviderBreadcrumb } from './breadcrumb';

interface Props {
  testId?: string;
}

export const ProviderItemList: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState(true);
  const [scrollEndLoading, setScrollEndLoading] = useState(false);
  const [providerItems, setProviderItems] = useState<ApiContentProviderItem[]>([]);
  const [currentPath, setCurrentPath] = useState('');
  const [metadata, setMetadata] = useState<GetContentProviderItemsMetadata>({});
  const { activeProviderId, selectedItemReferences } = useStoreState((state) => state.contentItems);
  const { fetchProviderItems, amendSelectedItemReference, setModel } = useStoreActions((state) => state.contentItems);
  const { addNotification } = useStoreActions((state) => state.notificationWidget);

  const scrollRef = useBottomScrollListener<HTMLDivElement>(() => {
    if (metadata.startAfter) {
      setScrollEndLoading(true);
      fetchProviderItems({
        providerId: activeProviderId,
        folderReference: currentPath,
        startAfter: metadata.startAfter,
        onSuccess(items, meta) {
          setScrollEndLoading(false);
          setProviderItems((state) => {
            const uniqueState = new Set([...state, ...items]);
            return [...uniqueState];
          });
          setMetadata(meta);
        }
      });
    }
  });

  const handleSelect = (itemReference: string) => () => {
    if (selectedItemReferences.length >= 5 && !selectedItemReferences.includes(itemReference)) {
      return addNotification({
        type: 'info',
        message: 'Only a max of 5 imports allowed at a time'
      });
    }
    amendSelectedItemReference({ itemReference });
  };

  const handleFolderExpand = (path: string) => () => {
    setLoading(true);
    fetchProviderItems({
      providerId: activeProviderId,
      folderReference: path,
      onSuccess(items, meta) {
        scrollRef.current.scrollTop = 0; // make sure always at top for new page
        setLoading(false);
        setProviderItems(items);
        setMetadata(meta);
        setCurrentPath(path);
      }
    });
  };

  useEffect(() => {
    if (activeProviderId) {
      fetchProviderItems({
        providerId: activeProviderId,
        onSuccess(items, meta) {
          setLoading(false);
          setProviderItems(items);
          setMetadata(meta);
        },
        onError(error) {
          if (error === 'Provider not found') {
            setModel({ activeProviderId: null });
          }
        }
      });
    }
  }, [activeProviderId]);

  return (
    <section data-testid={props.testId}>
      <ProviderBreadcrumb path={currentPath} handleFolderExpand={handleFolderExpand} />
      <ProviderItemListDiv ref={scrollRef}>
        <Loader loading={loading} backdrop />
        {loading && <div className="loadingPlaceholder" />}
        {providerItems.map((item) => (
          <ListRow
            item={item}
            selected={selectedItemReferences.includes(item.itemReference)}
            handleFolderExpand={handleFolderExpand(item.itemReference)}
            handleSelect={handleSelect(item.itemReference)}
            key={item.itemReference}
          />
        ))}
        {scrollEndLoading && (
          <div className="scrollEndLoader">
            <Loader loading={true} />
          </div>
        )}
      </ProviderItemListDiv>
    </section>
  );
};

interface ListRowProps {
  item: ApiContentProviderItem;
  selected?: boolean;
  handleSelect(): void;
  handleFolderExpand(): void;
}
const ListRow: React.FC<ListRowProps> = (props) => {
  const isFile = props.item.type === 'file';

  return (
    <>
      <ListRowDiv $type={props.item.type}>
        <div className="type">{isFile ? <DocIcon /> : <FolderIcon />}</div>
        <div className="metadata">
          <p>
            {props.item.name}
            <span>{props.item.itemReference}</span>
          </p>
        </div>
        <div className="action">
          {isFile ? (
            <Checkbox className="check" checked={props.selected} onChange={props.handleSelect} />
          ) : (
            <span className="expand" onClick={props.handleFolderExpand}>
              <CaretIcon />
            </span>
          )}
        </div>
      </ListRowDiv>
    </>
  );
};
