import { SideNavLinks } from '~store/sidenav/types';

export const settingsPageLinks: SideNavLinks[] = [
  {
    name: 'Api Keys',
    path: '/settings/api-keys'
  },
  {
    name: 'Credentials',
    path: '/settings/credentials'
  },
  {
    name: 'Tags',
    path: '/settings/tags'
  },
  {
    name: 'Geofences',
    path: '/settings/geofences'
  },
  {
    name: 'Content',
    path: '/settings/content'
  }
];
