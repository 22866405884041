import React from 'react';
import { Modal } from 'react-bootstrap';
import { Select, SelectOption } from '~components/select';
import { TextArea, TextField } from '~components/inputs';
import { ApiContentProvider } from '~api/content-items/types';
import { useStoreActions, useStoreState } from '~store/hooks';
import { ThirdPartyContentProviderType } from '@pushologies/common/constants/content-item';
import S3Icon from '~images/s3.svg';
import { StyledModal } from './styles';
import { Instructions } from './instructions';
import { Button } from '~components/button';

type ErrorsRecord = Partial<Record<keyof ApiContentProvider, string>>;
interface Props {
  show: boolean;
  closeModal(): void;
  addToProviderState(provider: ApiContentProvider): void;
}

const invalidUrl = (url: string) => {
  try {
    // tslint:disable-next-line:no-unused-expression
    new URL(url);
    return false;
  } catch {
    return true;
  }
};
const provideOptions: SelectOption[] = [
  {
    name: 'S3 BUCKET',
    value: ThirdPartyContentProviderType.AWS_S3,
    icon: S3Icon
  }
];

export const AddProviderModal: React.FC<Props> = ({ show, closeModal, addToProviderState }) => {
  const [activeProviderPage, setActiveProviderPage] = React.useState(0);
  const [errors, setErrors] = React.useState<ErrorsRecord>({});
  const { setModel, saveProvider } = useStoreActions((state) => state.contentItems);
  const { provider, status } = useStoreState((state) => state.contentItems);

  const selectProviderType = provideOptions.filter((option) => provider?.providerType?.includes(option.value));

  const handleNavButtonClick = (type: 'next' | 'back') => () => {
    let value: number;
    if (type === 'next') {
      if (checkForErrors()) return;
      value = activeProviderPage + 1;
    } else {
      value = activeProviderPage - 1;
    }
    setActiveProviderPage(value);
  };

  const handleCloseModal = () => {
    setActiveProviderPage(0);
    closeModal();
    setErrors({});
    setModel({ provider: {} });
  };

  const handleProviderSelect = (option: SelectOption[]) => {
    const selectedProvider = option.map((item) => item.value)[0];
    setModel({ provider: { ...provider, providerType: selectedProvider } });
  };

  const handleTextInputChange = (name: keyof ApiContentProvider) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    setModel({ provider: { ...provider, [name]: text } });
  };

  const handleTextareaChange = (name: keyof ApiContentProvider) => (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = event.target.value;
    setModel({ provider: { ...provider, [name]: text } });
  };

  const checkForErrors = () => {
    const newErrors: ErrorsRecord = {};
    if (activeProviderPage === 0) {
      !provider.providerType && (newErrors.providerType = 'A provider must be selected');
      !provider.name && (newErrors.name = 'A unique instance name must be set');
      !provider.bucketName && (newErrors.bucketName = 'Bucket/Folder name must be provided');
      provider.bucketDomainName &&
        invalidUrl(provider.bucketDomainName) &&
        (newErrors.bucketDomainName = 'Bucket Domain Name must be valid url');
      setErrors(newErrors);
    } else {
      !provider.region && (newErrors.region = 'Region is required');
      !provider.accessKeyId && (newErrors.accessKeyId = 'Access Key ID is required');
      !provider.secretAccessKey && (newErrors.secretAccessKey = 'Secret Access Key is required');
      setErrors(newErrors);
    }
    return !!Object.keys(newErrors).length;
  };

  const handleSaveProvider = () => {
    if (checkForErrors()) return;
    saveProvider({
      onSuccess(createdProvider) {
        addToProviderState(createdProvider);
        setModel({ provider: {} });
        closeModal();
      }
    });
  };

  return (
    <StyledModal show={show} backdrop="static" centered>
      <Modal.Body data-testid="videoBuilderModal">
        {activeProviderPage === 0 ? (
          <div className="pageProvider">
            <div className="inputField">
              <span>Choose Provider</span>
              <Select
                id="providerSelect"
                testId="proviederSelect"
                value={selectProviderType}
                options={provideOptions || []}
                placeholder="Select Provider"
                inValid={false}
                onChange={handleProviderSelect}
              />
              {errors.providerType && <p className="error">{errors.providerType}</p>}
            </div>
            <div className="inputField">
              <span>Unique Instance Name</span>
              <TextField
                name="instanceName"
                placeholder="Unique Instance Name"
                value={provider.name}
                onChange={handleTextInputChange('name')}
              />
              {errors.name && <p className="error">{errors.name}</p>}
            </div>
            <div className="inputField">
              <span>Bucket/Folder Name</span>
              <TextField
                name="bucketName"
                placeholder="Bucket Name"
                value={provider.bucketName}
                onChange={handleTextInputChange('bucketName')}
              />
              {errors.bucketName && <p className="error">{errors.bucketName}</p>}
            </div>
            <div className="inputField">
              <span>Bucket Domain Name</span>
              <span className="description">(Enter cdn or bucket domain name in full url form)</span>
              <span className="disclaimer">
                WARNING: If omitted please make sure your bucket has direct public access
              </span>
              <TextField
                name="bucketDomainName"
                placeholder="https://cdn.example.com"
                value={provider.bucketDomainName}
                onChange={handleTextInputChange('bucketDomainName')}
              />
              {errors.bucketDomainName && <p className="error">{errors.bucketDomainName}</p>}
            </div>
            <div className="inputField">
              <span>Description</span>
              <TextArea
                name="description"
                placeholder="Description"
                value={provider.description}
                onChange={handleTextareaChange('description')}
              />
            </div>
          </div>
        ) : (
          <>
            <Instructions />
            <div className="inputField">
              <span>Region</span>
              <TextField
                name="region"
                placeholder="Region"
                value={provider.region}
                onChange={handleTextInputChange('region')}
              />
              {errors.region && <p className="error">{errors.region}</p>}
            </div>
            <div className="inputField">
              <span>Access Key</span>
              <TextField
                name="accessKey"
                placeholder="********************"
                value={provider.accessKeyId}
                onChange={handleTextInputChange('accessKeyId')}
              />
              {errors.accessKeyId && <p className="error">{errors.accessKeyId}</p>}
            </div>
            <div className="inputField">
              <span>Secret Access Key</span>
              <TextField
                name="secretAccessKey"
                placeholder="********************"
                value={provider.secretAccessKey}
                onChange={handleTextInputChange('secretAccessKey')}
              />
              {errors.secretAccessKey && <p className="error">{errors.secretAccessKey}</p>}
            </div>
          </>
        )}
        <div className="modal-buttons">
          {activeProviderPage > 0 ? (
            <>
              <Button onClick={handleNavButtonClick('back')}>Back</Button>
              <Button
                onClick={handleSaveProvider}
                disabled={!!Object.keys(errors).length}
                loading={status === 'saving provider'}
              >
                Save
              </Button>
            </>
          ) : (
            <>
              <Button onClick={handleCloseModal}>Close</Button>
              <Button onClick={handleNavButtonClick('next')}>Next</Button>
            </>
          )}
        </div>
      </Modal.Body>
    </StyledModal>
  );
};
