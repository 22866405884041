import React, { useMemo } from 'react';
import { Link, NavLink, useLocation, useHistory } from 'react-router-dom';
import { Nav, Container } from 'react-bootstrap';
import classnames from 'classnames';
import UserIcon from '~images/user.svg';
import LogoutIcon from '~images/logout.svg';
import LoginIcon from '~images/login.svg';
import BellIcon from '~images/bell.svg';
import RegisterIcon from '~images/register.svg';
import { useStoreActions, useStoreState } from '~store/hooks';
import { HeaderDropDown, HeaderDropDownItem, HeaderDropDownLink, DropDownNavLink } from './dropdown';
import { StyledLogo, StyledNavBar, BellIconDiv, StyledTenantName } from './styles';
import { CopyWidget } from '~components/copy-widget';
import { Button } from '~components/button';
import { RoleGuard, userHasRequiredRole } from '../../role-guard';
import { useFlags } from 'launchdarkly-react-client-sdk';

const NavBar: React.FC = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [
    { logout },
    { toggleShowWidget },
    { setModel: setChildTenantModel, fetchChildTenants, resetSuperTenantState },
    { fetchTenant }
  ] = useStoreActions((state) => [state.user, state.notificationWidget, state.childTenants, state.tenant]);
  const { showWidget, hasNewNotification } = useStoreState(({ notificationWidget }) => notificationWidget);
  const { isSuperTenant, sessionApiKey } = useStoreState((state) => state.childTenants);
  const { tenant } = useStoreState((state) => state.tenant);
  const isAuthenticated = useStoreState((state) => state.user.isAuthenticated);
  const { hideUserManagement } = useFlags();

  const isAdmin = userHasRequiredRole(tenant?.userProfile?.role?.name, 'Admin');

  const logOut = () => {
    // ensure if our user logs into another account that super tenant state is reset
    resetSuperTenantState();
    logout();
  };
  const gotToPasswordReset = () => history.push('/forgot-password');
  const exitChildTenant = () => {
    resetSuperTenantState();
    setTimeout(() => (window.location.href = '/'), 500);
  };

  const handleToggleShowWidget = (event: React.MouseEvent) => {
    event.stopPropagation();
    toggleShowWidget();
  };

  React.useEffect(() => {
    if (isAuthenticated) {
      fetchTenant();
      isAdmin ? fetchChildTenants() : setChildTenantModel({ isSuperTenant: false });
    }
  }, [isSuperTenant, isAuthenticated]);

  return useMemo(
    () => (
      <StyledNavBar>
        <Link className="nav-link" to="/">
          <StyledLogo />
        </Link>
        {isAuthenticated && tenant?.name && (
          <StyledTenantName>
            <span>{tenant.name}</span>
            <CopyWidget copyText={tenant.id} copyMessage="copied tenant id" />
          </StyledTenantName>
        )}
        <Container id="main-links">
          {isAuthenticated && (
            <Nav as="ul">
              {isSuperTenant ? (
                <>
                  <Nav.Item as="li" data-testid="navbarMenuItem">
                    <NavLink className="nav-link" exact to="/">
                      Dashboard
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item as="li" data-testid="navbarMenuItem">
                    <HeaderDropDown
                      className={classnames('nav-link', { active: !!pathname.match(/^\/notifications/) })}
                      id="header-notifications"
                      button="Notifications"
                    >
                      <DropDownNavLink to="/notifications" exact>
                        View All
                      </DropDownNavLink>
                      <DropDownNavLink to="/notifications/create">Create</DropDownNavLink>
                    </HeaderDropDown>
                  </Nav.Item>
                  <Nav.Item as="li" data-testid="navbarMenuItem">
                    <NavLink className="nav-link" to="/media">
                      Media
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item as="li" data-testid="navbarMenuItem">
                    <HeaderDropDown
                      className={classnames('nav-link', { active: !!pathname.match(/^\/settings/) })}
                      id="header-settings"
                      button="Settings"
                    >
                      <DropDownNavLink to="/settings/users">Users</DropDownNavLink>
                    </HeaderDropDown>
                  </Nav.Item>
                </>
              ) : (
                <>
                  <Nav.Item as="li" data-testid="navbarMenuItem">
                    <NavLink className="nav-link" exact to="/">
                      Dashboard
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item as="li" data-testid="navbarMenuItem">
                    <HeaderDropDown
                      className={classnames('nav-link', { active: !!pathname.match(/^\/segments/) })}
                      id="header-segments"
                      button="Segments"
                    >
                      <DropDownNavLink data-testid="segmentsHeaderItem" to="/segments" exact>
                        View All
                      </DropDownNavLink>
                      <RoleGuard requiredRole="Creator">
                        <DropDownNavLink data-testid="segmentsHeaderItem" to="/segments/create" exact>
                          Create
                        </DropDownNavLink>
                      </RoleGuard>
                    </HeaderDropDown>
                  </Nav.Item>
                  <Nav.Item as="li" data-testid="navbarMenuItem">
                    <HeaderDropDown
                      className={classnames('nav-link', { active: !!pathname.match(/^\/notifications/) })}
                      id="header-notifications"
                      button="Notifications"
                    >
                      <DropDownNavLink to="/notifications" exact>
                        View All
                      </DropDownNavLink>
                      <RoleGuard requiredRole="Creator">
                        <DropDownNavLink to="/notifications/create">Create</DropDownNavLink>
                      </RoleGuard>
                    </HeaderDropDown>
                  </Nav.Item>
                  <Nav.Item as="li" data-testid="navbarMenuItem">
                    <HeaderDropDown
                      className={classnames('nav-link', { active: !!pathname.match(/^\/in-app/) })}
                      id="header-notifications"
                      button="In App Messaging"
                    >
                      <DropDownNavLink to="/in-app/" exact>
                        View All
                      </DropDownNavLink>
                      <RoleGuard requiredRole="Creator">
                        <DropDownNavLink to="/in-app/create">Create</DropDownNavLink>
                      </RoleGuard>
                    </HeaderDropDown>
                  </Nav.Item>
                  <Nav.Item as="li" data-testid="navbarMenuItem">
                    <NavLink className="nav-link" to="/subscriber-explorer">
                      Subscribers
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item as="li" data-testid="navbarMenuItem">
                    <NavLink className="nav-link" to="/media">
                      Media
                    </NavLink>
                  </Nav.Item>
                  <RoleGuard requiredRole="Admin">
                    <Nav.Item as="li" data-testid="navbarMenuItem">
                      <HeaderDropDown
                        className={classnames('nav-link', { active: !!pathname.match(/^\/settings/) })}
                        id="header-settings"
                        button="Settings"
                      >
                        <DropDownNavLink to="/settings/api-keys">Api Keys</DropDownNavLink>
                        <DropDownNavLink to="/settings/credentials">Credentials</DropDownNavLink>
                        <DropDownNavLink to="/settings/tags">Tags</DropDownNavLink>
                        {!hideUserManagement && <DropDownNavLink to="/settings/users">Users</DropDownNavLink>}
                        <DropDownNavLink to="/settings/geofences">Geofences</DropDownNavLink>
                        <DropDownNavLink to="/settings/content">Content Provider</DropDownNavLink>
                      </HeaderDropDown>
                    </Nav.Item>
                  </RoleGuard>
                </>
              )}
            </Nav>
          )}
        </Container>
        {sessionApiKey ? (
          <Button onClick={exitChildTenant}>Exit</Button>
        ) : (
          <HeaderDropDown id="header-user" button={<UserIcon />} testId="profileMenuItem">
            {isAuthenticated ? (
              <>
                <HeaderDropDownItem onClick={gotToPasswordReset}>Reset Password</HeaderDropDownItem>
                <HeaderDropDownItem onClick={logOut}>
                  Logout <LogoutIcon />
                </HeaderDropDownItem>
              </>
            ) : (
              <>
                <HeaderDropDownLink to="/login">
                  Login <LoginIcon />
                </HeaderDropDownLink>
                <HeaderDropDownLink to="/register">
                  Register <RegisterIcon />
                </HeaderDropDownLink>
              </>
            )}
          </HeaderDropDown>
        )}
        {isAuthenticated && (
          <BellIconDiv $hasNew={hasNewNotification} $active={showWidget} onClick={handleToggleShowWidget}>
            <BellIcon />
          </BellIconDiv>
        )}
      </StyledNavBar>
    ),
    [pathname, isAuthenticated, isSuperTenant, showWidget, hasNewNotification, tenant]
  );
};

export default NavBar;
