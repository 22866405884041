import React from 'react';
import { CloseButton } from 'react-bootstrap';
import { SidePanelDiv, StyledFormHeader } from './styles';
import { useClickAway } from '~hooks/click-away';

interface Props {
  show: boolean;
  title: string;
  className?: string;
  testId?: string;
  closeOnBackdropClick?: boolean;
  onClose?(): void;
}

export const SidePanel: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const modalRef = React.useRef<HTMLDivElement>();

  useClickAway(modalRef, () => {
    if (props.closeOnBackdropClick && props.show) props.onClose();
  });

  return (
    <SidePanelDiv $show={props.show} className={props.className} data-testid={props.testId}>
      <div ref={modalRef} className="sidePanel__content">
        <StyledFormHeader>
          {props.title} {props?.onClose && <CloseButton data-testid={`${props.testId}Close`} onClick={props.onClose} />}
        </StyledFormHeader>
        <section className="sidePanel__content__section">{props.children}</section>
      </div>
    </SidePanelDiv>
  );
};
