import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ThemeProvider } from 'styled-components';
import { StoreProvider, useStoreRehydrated } from 'easy-peasy';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import 'react-toastify/dist/ReactToastify.css';
import NavBar from '~components/header';
import { SideNav } from '~components/side-nav';
import { NotificationWidget } from '~components/notification-widget';
import { GlobalStyles } from '~styles/index';
import theme from '~styles/theme';
import { Routes } from 'src/routes/index';
import { store } from '~store/index';
import '~styles/fonts/fonts.css';

const history: any = createBrowserHistory();

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  release: process.env.APP_VERSION,
  enabled: ['dev', 'test', 'prod'].includes(process.env.STAGE),
  environment: process.env.STAGE,
  integrations: [
    new Integrations.BrowserTracing({
      // Can also use reactRouterV4Instrumentation
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
    })
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0
});

const WaitForStateRehydration: React.FC<React.PropsWithChildren> = ({ children }) => {
  const isRehydrated = useStoreRehydrated();
  return isRehydrated ? <>{children}</> : null;
};

const App = () => {
  return (
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <ToastContainer transition={Slide} />
        <StoreProvider store={store}>
          <BrowserRouter>
            <WaitForStateRehydration>
              <GlobalStyles />
              <NavBar />
              <SideNav />
              <NotificationWidget />
              <Routes />
            </WaitForStateRehydration>
          </BrowserRouter>
        </StoreProvider>
      </ThemeProvider>
    </Router>
  );
};

export default withLDProvider({
  clientSideID: process.env.LAUNCHDARKLY_CLIENT_API_KEY,
  user: { key: process.env.LAUNCHDARKLY_USER_KEY, anonymous: true },
  options: { useReport: true, sendEvents: false }
})(App);
