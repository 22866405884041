import React from 'react';
import { ApiContentItem, ApiContentProviderItemPayload } from '~api/content-items/types';
import { StyledButton, StyledModal } from '../styles';
import { CONTENT_ITEM_PRESENTATION_TYPE } from '@pushologies/common/constants/content-item';
import { useStoreActions } from '~store/hooks';

export interface ImportsPayload {
  failed: ApiContentProviderItemPayload[];
  existing: ApiContentProviderItemPayload[];
  success: ApiContentProviderItemPayload[];
}
interface Props extends ImportsPayload {
  onHide(): void;
  presentation: CONTENT_ITEM_PRESENTATION_TYPE[];
}

export const ImportModal: React.FC<Props> = (props) => {
  const updateableItems = props.existing
    ?.filter?.(({ item }) => item.presentation.some((type) => !props.presentation.includes(type)))
    .map(({ item }) => item.id);
  const handleModalClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <StyledModal
      show={props?.existing?.length || props?.failed?.length}
      data-testid="importContentProviderItemsModal"
      centered
      onHide={props.onHide}
      onClick={handleModalClick}
    >
      {!!props?.success?.length && (
        <>
          <h3>Successful Imports</h3>
          {props?.success?.map(({ item }) => (
            <div>{item.name}</div>
          ))}
        </>
      )}
      {!!props?.existing?.length && (
        <>
          <h3>Existing Imports</h3>
          {props?.existing?.map(({ item }) => (
            <div>
              {item.name}
              <UpdateSpanButton
                item={item}
                presentation={props.presentation}
                updateable={updateableItems.includes(item.id)}
              />
            </div>
          ))}
        </>
      )}
      {!!props?.failed?.length && (
        <>
          <h3>Failed Imports</h3>
          {props?.failed?.map(({ error }) => (
            <div>{error}</div>
          ))}
        </>
      )}
    </StyledModal>
  );
};

const UpdateSpanButton: React.FC<{
  item: ApiContentItem;
  presentation: CONTENT_ITEM_PRESENTATION_TYPE[];
  updateable: boolean;
}> = (props) => {
  const [loading, setLoading] = React.useState(false);
  const { updateItem } = useStoreActions((state) => state.contentItems);

  const handleUpdateContentItem = (item: ApiContentItem) => () => {
    setLoading(true);
    updateItem({
      id: item.id,
      presentation: props.presentation,
      onSuccess(_) {
        setLoading(false);
      }
    });
  };

  return (
    <StyledButton
      className="updateBtn"
      onClick={handleUpdateContentItem(props.item)}
      loading={loading}
      disabled={!props.updateable}
    >
      {props.updateable ? 'Update presentation type' : 'Presentation updated'}
    </StyledButton>
  );
};
