import React from 'react';
import { useStoreState, useStoreActions } from '~store/hooks';
import { Button } from '~components/button';
import { Tour } from '~components/tour';
import { SegmentRule } from './rule';
import { SegmentHeader } from './header';
import { RuleList } from './rule-list';
import { segmentSteps } from './tour';
import { RulesWrapperDiv, NoRulesP, WrapperDiv, ButtonsWrapperDiv, CardsWrapperDiv } from './styles';
import { useParams, useLocation } from 'react-router-dom';

interface Props {
  minified?: boolean;
  showTour?: boolean;
  onSave?(): void;
  onCancel?(): void;
}

export const SegmentBuilder: React.FC<Props> = ({ minified, onCancel, onSave, showTour = true }) => {
  const isInvalid = useStoreState(({ createSegment }) => createSegment.isInvalid);
  const { createSegmentLoading, rules } = useStoreState(({ createSegment }) => createSegment);
  const { user } = useStoreState((state) => state.user);
  const { persistSegment } = useStoreActions((state) => state.createSegment);
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const SEGMENT_TOUR_COOKIE = `SEGMENT_TOUR_COOKIE_${user?.username}_v1`;

  React.useEffect(() => {
    // “id” from url is only a segmentId when not used on a notification/inApp pages
    if (id && !location?.pathname?.startsWith('/notifications') && !location?.pathname?.startsWith('/in-app')) {
      persistSegment({ id });
    }
  }, [id]);

  return (
    <>
      <WrapperDiv minified={minified}>
        <RuleList rules={rules} />
        <CardsWrapperDiv>
          <SegmentHeader columnAlignment={minified} />
          <RulesWrapperDiv hasRules={!!rules.length} data-testid="segmentRulesBody" data-tour="rules">
            {rules.map((rule) => (
              <SegmentRule key={rule.id} rule={rule} minified={minified} />
            ))}
            {!rules.length && <NoRulesP>Click on rules on the left to add to segment</NoRulesP>}
          </RulesWrapperDiv>

          {minified && (
            <ButtonsWrapperDiv>
              <Button variant="secondary" onClick={onCancel}>
                cancel
              </Button>
              <Button
                loading={createSegmentLoading}
                onClick={onSave}
                disabled={isInvalid}
                testId="saveSegmentButton"
                data-tour="segmentSave"
              >
                save
              </Button>
            </ButtonsWrapperDiv>
          )}
        </CardsWrapperDiv>
      </WrapperDiv>

      {showTour && user && <Tour id={SEGMENT_TOUR_COOKIE} steps={segmentSteps} />}
    </>
  );
};
