import React from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from 'src/routes/private-route';
import { ApiKeys } from './api-keys';
import { Credentials } from './credentials';
import { Tags } from './tags';
import { Users } from './users';
import { Geofences } from 'src/pages/settings/geofences';
import { Content } from './content';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const Settings: React.FC = () => {
  const { path } = useRouteMatch();
  const { hideUserManagement } = useFlags();

  return (
    <Switch>
      <PrivateRoute exact path={path}>
        <Redirect to={`${path}/api-keys`} />
      </PrivateRoute>
      <PrivateRoute path={`${path}/api-keys`}>
        <ApiKeys />
      </PrivateRoute>
      <PrivateRoute path={`${path}/credentials`}>
        <Credentials />
      </PrivateRoute>
      <PrivateRoute path={`${path}/tags`}>
        <Tags />
      </PrivateRoute>
      {!hideUserManagement && (
        <PrivateRoute path={`${path}/users`}>
          <Users />
        </PrivateRoute>
      )}
      <PrivateRoute path={`${path}/geofences`}>
        <Geofences />
      </PrivateRoute>
      <PrivateRoute path={`${path}/content`}>
        <Content />
      </PrivateRoute>
      <Redirect to="/404" />
    </Switch>
  );
};
