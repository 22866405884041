import styled, { css } from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { BarChart } from '~components/bar-chart';
import { EnlargedScrollbar } from '~styles/mixins';
import { StyledDropdown } from '~components/select/styles';
import { DeviceWrapperDiv } from '~components/preview-device/styles';
import { PieChart } from '~components/pie-chart';

export const StyledRow = styled(Row)`
  justify-content: space-between;
  margin: 2rem 4rem 1.5rem !important;

  div {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.darkGrey};

    h1 {
      font-family: EavesBold;
      font-size: 2.75rem;
      margin: 0;
      color: ${({ theme }) => theme.palette.platinum};
      text-transform: uppercase;

      .personalisation {
        font-size: 1.5rem;
      }
    }

    span.status {
      font-family: EavesBold;
      margin-left: 0.75rem;
      color: ${({ theme }) => theme.palette.primaryPlum};
      text-transform: uppercase;
    }
  }

  #notificationTimeInfo {
    p,
    span {
      margin: 0;
      display: flex;
      align-items: center;
      font-family: EavesBold;
      font-size: 0.9rem;
      color: ${({ theme }) => theme.palette.platinum};
      text-transform: uppercase;
    }
  }

  .dropdown {
    width: 100%;
  }

  #notificationTimeInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    p,
    > span {
      display: block;
      text-align: right;
      width: 100%;
    }
  }

  .nav {
    .nav-item {
      position: relative;
      flex-grow: 2;
      text-align: center;
      width: 200px;

      &:first-child {
        border-right: 1px solid ${({ theme }) => theme.palette.lighterGrey};
      }

      a {
        width: 100%;
        text-transform: uppercase;
        color: ${({ theme }) => theme.palette.textLightGrey};

        &:hover {
          color: ${({ theme }) => theme.palette.primaryMustard};
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: ${({ theme }) => theme.palette.lighterGrey};
        }

        &.active {
          color: ${({ theme }) => theme.palette.primaryPlum};

          &::after {
            height: 4px;
            background: ${({ theme }) => theme.palette.primaryPlum};
          }
        }
      }
    }
  }

  .tab-pane {
    text-align: center;

    .spinner-border {
      position: relative;
      margin-bottom: 2rem;
      color: ${({ theme }) => theme.palette.darkGrey};
    }
  }
`;

export const BottomStyledRow = styled(Row)`
  margin: 2rem 4rem !important;
  border: 1px solid ${({ theme }) => theme.palette.primaryPlum};
  box-shadow: 0px 0 2rem -1rem rgba(0, 0, 0, 0.35);

  .top {
    display: flex;
    justify-content: space-between;
    margin: 0.75rem 0 1rem 0;

    .details {
      display: flex;
      align-items: center;

      h4 {
        color: ${({ theme }) => theme.palette.primaryPlum};
        font-family: EavesBold;
        text-transform: uppercase;
        margin: 0;
      }

      p {
        text-transform: uppercase;
        font-family: EavesBold;
        color: ${({ theme }) => theme.palette.navGreyColor};
        margin: 0 1rem;
      }
    }

    .metadata {
      display: flex;
      align-items: center;

      h6 {
        margin: 0 1rem;
        color: ${({ theme }) => theme.palette.primaryPlum};
        font-family: EavesBold;
        font-size: 1.05rem;
        text-transform: uppercase;
        white-space: nowrap;
      }

      svg {
        width: 1.25rem;

        path {
          fill: ${({ theme }) => theme.palette.navGreyColor};
        }

        &.notification_carousel_svg__icon {
          width: 1.6rem;
        }
      }
    }
  }

  .bottom {
    margin-bottom: 1rem;

    .col-md-9 {
      padding-left: 0;
    }
  }

  .videoInfo {
    .col-md-12 {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        display: inline-block;
        margin: 0 1rem 1rem;
        text-transform: uppercase;
        font-family: EavesBold;
        color: ${({ theme }) => theme.palette.navGreyColor};

        span {
          color: ${({ theme }) => theme.palette.primaryPlum};
        }
      }
    }
  }
`;

export const TabContentDiv = styled.div`
  height: 44rem;
  overflow-y: scroll;
  background: ${({ theme }) => theme.palette.faintGrey};

  ${EnlargedScrollbar}

  i {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 1rem;
    color: ${({ theme }) => theme.palette.platinum};
    font-size: 0.95rem;
  }
`;

export const PreviewCol = styled(Col)`
  border: 1px solid ${({ theme }) => theme.palette.lightGrey};
  background: ${({ theme }) => theme.palette.faintGrey};
  padding: 0.5rem;

  ${DeviceWrapperDiv} {
    height: unset;
  }

  ${StyledDropdown} {
    transform: translateX(-10%);
    margin-left: 20%;

    button {
      padding: 0.1rem 0.5rem;
    }
  }
`;

export const DeviceRow = styled(Row)`
  justify-content: center;
`;

export const NotificationTotalStyledRow = styled(Row)`
  margin: 0 4rem !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  div[class^='col'] {
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }

  .notification-total {
    position: relative;
    min-width: 1rem;
    margin: 0 0.4rem;
    color: ${({ theme }) => theme.palette.darkGrey};
    text-transform: uppercase;

    h3 {
      text-transform: uppercase;
      font-family: EavesBold;
      margin: 0;
      letter-spacing: 0;
    }

    h2 {
      text-transform: uppercase;
      font-family: EavesBold;
      font-size: 5rem;
    }

    p {
      color: ${({ theme }) => theme.palette.darkGrey};
      font-weight: bold;

      &.total {
        margin: 1rem 0 -0.75rem;
      }
    }

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0.3rem 0;

      p {
        font-size: 3.5rem;
        letter-spacing: 0;
        font-family: EavesBold;
        margin: 0;
        line-height: 3rem;
        transform: translateY(10%);
      }

      &.android {
        p {
          color: ${({ theme }) => theme.palette.android};
        }
      }
      &.apple {
        p {
          color: ${({ theme }) => theme.palette.apple};
        }
      }
    }
  }
`;

export const StyledBarChart = styled(BarChart)`
  margin: 0 0.5rem;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

interface BarChartRowProps {
  $chartNum: number;
}

export const BarChartRow = styled(Row)<BarChartRowProps>`
  justify-content: ${({ $chartNum }) => ($chartNum > 4 ? 'space-between' : 'center')};

  ${({ $chartNum }) =>
    $chartNum > 4 &&
    css`
      ${StyledBarChart} {
        margin: 0;
      }
    `}
`;

export const ButtonStatsDiv = styled.div`
  position: relative;
  display: flex;
  margin: 0.5rem;
  border: 1px solid ${({ theme }) => theme.palette.textGrey};
  background: white;
  height: 4.5rem;

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 25rem;
    padding-left: 1rem;

    h5 {
      margin: 0 0 0.25rem 0;
      align-self: flex-start;
      text-transform: uppercase;
      font-size: 0.85rem;
    }

    & > div {
      line-height: 0.7rem;

      p {
        display: inline-block;
        margin: 0 1rem 0 0;
        font-size: 0.75rem;
        text-transform: uppercase;
        font-family: EavesBold;
        color: ${({ theme }) => theme.palette.navGreyColor};

        span {
          color: ${({ theme }) => theme.palette.primaryPlum};
        }
      }
    }
  }

  .stats {
    position: relative;
    flex-grow: 2;
    padding-left: 2rem;

    svg {
      position: absolute;
      left: 0.5rem;
      width: 1rem;

      &.androidIcon {
        top: 0.5rem;
      }

      &.appleIcon {
        top: 2.5rem;
      }
    }
  }
`;

interface ButtonImgDivProps {
  $url?: string;
}
export const ButtonImgDiv = styled.div<ButtonImgDivProps>`
  height: 100%;
  width: 6rem;
  background: url('${({ $url }) => $url}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

interface ButtonTextDivProps {
  $textColor: string;
  $backgroundColor: string;
  $borderColor: string;
}
export const ButtonTextDiv = styled.div<ButtonTextDivProps>`
  align-self: center;
  margin-left: 0.1rem;
  padding: 0.25rem 0;
  width: 5.9rem;
  font-size: 0.9rem;
  line-height: 0.8rem;
  text-align: center;
  background: ${({ $backgroundColor, theme }) => $backgroundColor || theme.palette.primaryPlum};
  color: ${({ $textColor, theme }) => $textColor || theme.palette.primaryMustard};
  border: ${({ $borderColor }) => ($borderColor ? `1px solid ${$borderColor}` : 'none')};
`;

export const StyledPieChart = styled(PieChart)`
  margin: 0 0.5rem;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;
