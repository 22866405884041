import React from 'react';
import PlusIcon from '~images/plus.svg';
import { Props as TilesViewerUploadProps } from '~components/file-viewers/tiles-upload';
import { FileUploadRef } from '~components/file-upload';
import { InputSection } from '~components/inputs/styles';
import { trimText } from '~helpers/formats';
import { useClickAway } from '~hooks/click-away';
import { ContentWrapper, AddIconSpan, StyledContentItemsFileViewer, Placement } from './styles';
import { CONTENT_ITEM_PRESENTATION_TYPE } from '@pushologies/common/constants/content-item';
import { ApiContentItem } from '~api/content-items/types';

interface Props<T> extends Omit<TilesViewerUploadProps<T>, 'fileUploadRef'> {
  label?: string;
  value: string;
  disabled?: boolean;
  placement: Placement;
  presentation: CONTENT_ITEM_PRESENTATION_TYPE[];
  onImport(items: ApiContentItem[]): void;
}

export const TilesViewerUploadField = React.forwardRef(
  <T extends { id: string }>(
    { label, value, disabled, placement, testId, ...props }: React.PropsWithChildren<Props<T>>,
    ref: React.ForwardedRef<FileUploadRef>
  ) => {
    const inputSectionRef = React.useRef<HTMLElement>();
    const [show, setShow] = React.useState(false);
    const handleShowChange = () => setShow(!show);
    const {
      onUpload,
      acceptedFiles,
      fileUploadLabel,
      className,
      uploadMultiple,
      uploadButtonText,
      uploadPresetExternalFields,
      uploadDisableExternalFields,
      onExternalContentAdded,
      ...rest
    } = props;

    useClickAway(inputSectionRef, () => show && setShow(false));

    return (
      <InputSection ref={inputSectionRef} data-testid={testId}>
        {label && <label>{label}</label>}
        <ContentWrapper>
          <span className="valueSpan">{trimText(value, 30)}</span>
          <AddIconSpan id={`${testId}Open`} onClick={handleShowChange}>
            <PlusIcon />
          </AddIconSpan>
          {show && (
            <StyledContentItemsFileViewer
              tilesViewer={rest}
              tilesViewerUpload={{
                onUpload,
                acceptedFiles,
                fileUploadLabel,
                className,
                uploadMultiple,
                uploadButtonText,
                uploadPresetExternalFields,
                uploadDisableExternalFields,
                onExternalContentAdded
              }}
              ref={ref}
              $placement={placement}
              testId={`${testId}TilesViewerUpload`}
              presentation={props.presentation}
              onImport={props.onImport}
            />
          )}
        </ContentWrapper>
      </InputSection>
    );
  }
);
