import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { Col } from 'react-bootstrap';
import { useRouteMatch } from 'react-router-dom';
import { PUSH_SCHEDULE_MODE } from '@pushologies/database-service/db/entities/scheduled-push';
import { GEOFENCE_TRIGGER } from '@pushologies/common/constants/subscriber';
import { DateTimePicker } from '~components/inputs/date-time-picker';
import { ListViewer, ListFile } from '~components/file-viewers/list';
import { useStoreState, useStoreActions } from '~store/hooks';
import { ApiSegment, ApiSegmentBucket } from '~api/segments/types';
import { ViewerHeader } from '~components/file-viewers/header';
import { useFileViewerOptions } from '~components/file-viewers/hooks';
import { SegmentBuilder } from '~components/segment-builder';
import { SidePanel } from '~components/side-panel';
import { numberCommaFormat } from '~helpers/formats';
import { Select, SelectOption } from '~components/select';
import { DrawingModes, LocationField } from '~components/inputs/location';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isInAppPage } from '~pages/notifications/helpers';
import {
  SegmentsColumnContentDiv,
  SchedulingColumnContentDiv,
  StyledToggleField,
  GeofenceSection,
  StyledInputSection,
  CreateButton,
  DynamismColumnSpan,
  LabelDiv,
  StaggerSection,
  StyledDisclaimer
} from './styles';
import { InAppInputs } from './in-app';
import { StyledFormHeader, ColumnDiv } from '../styles';
import { NumberField } from '~components/inputs/number-field';
import { InfoBanner } from '~components/info-banner';

const SEGMENT_LIMIT = 20;
const segmentDisplayKeys = [
  'name',
  { key: 'buckets', columnName: 'Size' },
  { key: 'dynamism', columnName: 'Dynamism' }
];
const segmentDisplayFormat = {
  buckets: (buckets: ApiSegmentBucket[]) => {
    if (!buckets?.length) return '-';

    const sizes = buckets.map((b) => b.size);

    // if only one bucket or all buckets hav same size display one size value
    if (new Set(sizes).size === 1) return numberCommaFormat(buckets[0].size);
    // if multiple buckets with varying sizes display max size
    return `${numberCommaFormat(Math.max(...sizes))}`;
  },
  dynamism: (value: string) => <DynamismColumnSpan>{value ?? '-'}</DynamismColumnSpan>
};
const segmentColumnWidths = {
  name: '60%'
};

interface RightColumnProps {
  tenantSegmentMap?: Record<string, string>;
  setTenantSegmentMap?: Dispatch<SetStateAction<Record<string, string>>>;
}

// tslint:disable:cyclomatic-complexity
export const RightColumn: React.FC<RightColumnProps> = ({ tenantSegmentMap, setTenantSegmentMap }) => {
  const router = useRouteMatch();
  const [showSegmentBuilder, setShowSegmentBuilder] = React.useState(false);
  const [showGeofence, setShowGeofence] = React.useState(false);
  const [showStagger, setShowStagger] = React.useState(false);
  const [segments, setSegments] = React.useState<ApiSegment[]>([]);
  const [searchText, setSearchText] = React.useState('');
  const [selectedTenant, setSelectedTenant] = React.useState<SelectOption[]>([]);
  const { childTenants, isSuperTenant } = useStoreState((state) => state.childTenants);
  const { loading } = useStoreState((state) => state.segments);
  const { notification, status } = useStoreState((state) => state.createNotification);
  const { setScheduling, setGeofencing, setStaggering, setNotification } = useStoreActions(
    (state) => state.createNotification
  );
  const { saveSegment, resetModel } = useStoreActions((state) => state.createSegment);
  const { fetchSegments } = useStoreActions((state) => state.segments);
  const { options, setOptions } = useFileViewerOptions();
  const schedulingEnabled = !!notification?.scheduling;
  const {
    hideGeoFenceRepeatAdminUi,
    hideGeoFenceTriggerAdminUi,
    hideGeoFenceStartEndTimesAdminUi,
    hideGeoFenceSchedulingAdminUi,
    hideExitTriggerAdminUi,
    hidePolygonNotificationBuilderAdminUi,
    hideDeliverySchedulingSwitch,
    hideInappExpiresAtField
  } = useFlags();
  const triggerOptions: SelectOption<GEOFENCE_TRIGGER>[] = [
    {
      name: 'ENTRY',
      value: GEOFENCE_TRIGGER.ENTRY
    },
    ...(hideExitTriggerAdminUi ? [] : [{ name: 'EXIT', value: GEOFENCE_TRIGGER.EXIT }])
  ];
  const allowedDrawingModes: DrawingModes = hidePolygonNotificationBuilderAdminUi ? ['marker'] : ['marker', 'polygon'];
  const isNotInAppPage = !isInAppPage(router);

  const handleSegmentSelect = (files: ListFile[]) => {
    if (isSuperTenant) {
      const tenantId = selectedTenant[0].value;
      const segmentId = files?.[0]?.id;
      const newTenantSegmentMap: Record<string, string> = { ...tenantSegmentMap, [tenantId]: segmentId };
      // are setting an undefined segmentId - we'll need to unset the key for validation purposes
      if (!segmentId) {
        delete newTenantSegmentMap[tenantId];
      }
      setTenantSegmentMap(newTenantSegmentMap);
    }
    setNotification(files.length ? { segment: { id: files[0].id } } : { segment: undefined });
  };

  const handleScheduleChange = (show: boolean) => {
    if (show) {
      setScheduling();
    } else {
      setScheduling({ mode: PUSH_SCHEDULE_MODE.NORMAL });
    }

    toggleGeofence(false);
  };

  const handleGeofenceChange = (data: any) => {
    setGeofencing(data);
  };

  const handleStaggerChange = (key: 'intervals' | 'batchSize') => (value: number) => {
    setStaggering({ [key]: value });
  };

  const handleTriggerOnChange = (selected: SelectOption[]) => {
    setGeofencing({ trigger: selected[0]?.value });
  };

  const handleLocalTimezoneChange = (localTime: boolean) => {
    setScheduling({ mode: localTime ? PUSH_SCHEDULE_MODE.TIMEZONE : PUSH_SCHEDULE_MODE.NORMAL });
  };

  const handlePushAtChange = (date: Date) => {
    setScheduling({ pushAt: date.toISOString() });
  };

  const handleSaveSegment = () => {
    saveSegment({
      onSuccess(segment) {
        setSegments([segment, ...segments]);
        setShowSegmentBuilder(false);
      }
    });
  };

  const toggleShowSegmentBuilder = (show?: boolean) => () => {
    const newShow = typeof show === 'boolean' ? show : !showSegmentBuilder;
    setShowSegmentBuilder(newShow);

    if (!newShow) resetModel();
  };

  // geofence and stagger cannot be used in conjunction
  const toggleGeofence = (checked: boolean) => {
    if (!checked) {
      setGeofencing();
      setShowGeofence(!checked);
    }
    setShowGeofence(checked);
  };

  const toggleStagger = (checked: boolean) => {
    if (!checked) {
      setStaggering();
      setShowStagger(!checked);
    }
    setShowStagger(checked);
  };

  const toggleGeofenceRepeat = (checked: boolean) => {
    setGeofencing({ repeat: checked });
  };

  const handleSegmentsFilterChange = (text: string) => {
    setSearchText(text);
  };

  const handleSearchSubmit = () => {
    fetchSegments({
      limit: SEGMENT_LIMIT,
      offset: options.offset,
      name: searchText,
      includeBuckets: true,
      ...(selectedTenant[0] && { tenantId: selectedTenant[0].value }),
      onSuccess(newSegments, totalItems) {
        setSegments(newSegments);
        setOptions({ totalItems });
      }
    });
  };

  const handlePageChange = (offset: number) => {
    fetchSegments({
      limit: SEGMENT_LIMIT,
      offset,
      name: searchText,
      includeBuckets: true,
      ...(selectedTenant[0] && { tenantId: selectedTenant[0].value }),
      onSuccess(newSegments, totalItems) {
        setSegments(newSegments);
        setOptions({ totalItems, offset });
      }
    });
  };

  const tenantOptions: SelectOption[] = React.useMemo(
    () =>
      Object.values(childTenants || {}).map(({ id, name }) => ({
        name: `${tenantSegmentMap[id] ? '✅ - ' : ''} ${name}`,
        value: id
      })),
    [childTenants, tenantSegmentMap]
  );

  React.useEffect(() => {
    // set initial value
    !selectedTenant.length && tenantOptions.length && setSelectedTenant([tenantOptions[0]]);
  }, [tenantOptions]);

  React.useEffect(() => {
    fetchSegments({
      limit: SEGMENT_LIMIT,
      offset: 0,
      name: searchText,
      includeBuckets: true,
      ...(selectedTenant[0] && { tenantId: selectedTenant[0].value }),
      onSuccess(newSegments, totalItems) {
        setSegments(newSegments);
        setOptions({ totalItems, offset: 0 });
      }
    });
  }, [selectedTenant]);

  const handleTenantOnChange = (selected: SelectOption[]) => {
    setSelectedTenant(selected);
  };

  React.useEffect(() => {
    if (!!notification?.geofence) {
      setShowGeofence(true);
    }

    if (!!notification?.stagger) {
      setShowStagger(true);
    }
  }, [notification]);

  return useMemo(
    () => (
      <Col md="4">
        <ColumnDiv>
          <ViewerHeader
            testId="segmentsViewerHeader"
            title="Segments"
            filterValue={searchText}
            filterPlaceholder="Filter by Name"
            onTextChange={handleSegmentsFilterChange}
            onSearchSubmit={handleSearchSubmit}
          />

          <SegmentsColumnContentDiv>
            {isSuperTenant && (
              <Select
                id="tenantSegmentSelect"
                testId="tenantSegmentSelect"
                label="Tenant"
                value={selectedTenant}
                options={tenantOptions}
                onChange={handleTenantOnChange}
                drop="down"
                inValid={false}
              />
            )}
            <ListViewer
              testId="segmentsViewer"
              files={segments}
              displayKeys={segmentDisplayKeys}
              displayKeyFormat={segmentDisplayFormat}
              onSelect={handleSegmentSelect}
              selectedFiles={
                isSuperTenant
                  ? ([{ id: tenantSegmentMap[selectedTenant?.[0]?.value] }] as any)
                  : [notification?.segment]
              }
              columnWidths={segmentColumnWidths}
              loading={loading === 'fetchingSegments'}
              pagination={{
                limit: SEGMENT_LIMIT,
                offset: options.offset,
                totalItems: options.totalItems,
                onPageChange: handlePageChange
              }}
            />
            {!isSuperTenant && (
              <CreateButton testId="showMiniSegmentBtn" onClick={toggleShowSegmentBuilder()}>
                Add New Segment
              </CreateButton>
            )}
            <SidePanel show={showSegmentBuilder} title="Create New Segment">
              <SegmentBuilder
                minified
                onCancel={toggleShowSegmentBuilder(false)}
                onSave={handleSaveSegment}
                showTour={showSegmentBuilder}
              />
            </SidePanel>
          </SegmentsColumnContentDiv>
        </ColumnDiv>
        <ColumnDiv>
          <StyledFormHeader>Delivery Options</StyledFormHeader>
          {!isNotInAppPage && !hideInappExpiresAtField && (
            <InfoBanner message="Expires At supported in SDK 4.9.0 and above" />
          )}
          <SchedulingColumnContentDiv>
            <InAppInputs />
            <StyledToggleField
              id="schedulingSendNow"
              label="Send Now"
              value={!schedulingEnabled}
              onChange={handleScheduleChange}
            />
            <StyledInputSection $disabled={!schedulingEnabled} data-testid="schedulingSendAt">
              <label htmlFor="schedulingSendAt">Send At</label>
              <DateTimePicker
                id="schedulingSendAt"
                value={notification?.scheduling?.pushAt && new Date(notification.scheduling.pushAt)}
                onChange={handlePushAtChange}
                disabled={!schedulingEnabled}
                invalid={schedulingEnabled && !notification.scheduling.pushAt}
              />
            </StyledInputSection>
            {!hideDeliverySchedulingSwitch && (
              <StyledToggleField
                id="timezoneScheduled"
                label="Schedule delivery in subscriber local timezone"
                value={notification?.scheduling?.mode === PUSH_SCHEDULE_MODE.TIMEZONE}
                onChange={handleLocalTimezoneChange}
                disabled={!schedulingEnabled}
              />
            )}
            {!hideGeoFenceSchedulingAdminUi && isNotInAppPage && (
              <GeofenceSection data-testid="geofenceSection">
                <StyledToggleField
                  id="geofenceToggle"
                  label="Geofence"
                  value={showGeofence}
                  onChange={toggleGeofence}
                  disabled={!!notification?.scheduling?.mode || showStagger}
                />
                {showGeofence && (
                  <>
                    <StyledInputSection $disabled={!showGeofence} data-testid="geofenceLocationSection">
                      <label htmlFor="geofenceLocation">Location Details</label>
                      <LocationField
                        latitude={notification?.geofence?.latitude}
                        longitude={notification?.geofence?.longitude}
                        radius={notification?.geofence?.radius}
                        startDate={notification?.geofence?.startDate}
                        endDate={notification?.geofence?.endDate}
                        vertices={notification?.geofence?.vertices}
                        onChange={handleGeofenceChange}
                        testId="geofenceLocation"
                        displayDateFields={!hideGeoFenceStartEndTimesAdminUi}
                        disabled={!showGeofence}
                        allowedDrawingModes={allowedDrawingModes}
                      />
                    </StyledInputSection>
                    {!hideGeoFenceTriggerAdminUi && (
                      <StyledInputSection $disabled={!showGeofence} data-testid="geofenceTrigger">
                        <LabelDiv>
                          <label htmlFor="triggerSelect">
                            Trigger
                            <span>Point at which notification is shown when geofence boundary is crossed</span>
                          </label>
                        </LabelDiv>
                        <Select
                          id="triggerSelect"
                          testId="geofenceTriggerSelect"
                          value={triggerOptions.filter((opt) => opt.value === notification?.geofence?.trigger)}
                          options={triggerOptions}
                          onChange={handleTriggerOnChange}
                          drop="down"
                          placeholder="Select Trigger"
                          inValid={false}
                          disabled={!showGeofence}
                        />
                      </StyledInputSection>
                    )}
                    {!hideGeoFenceRepeatAdminUi && (
                      <StyledInputSection $disabled={!showGeofence} data-testid="geofenceRepeat">
                        <LabelDiv>
                          <label htmlFor="repeatToggle">
                            Repeat
                            <span>
                              Specifies whether the geofence should be triggered only once after initial crossing or
                              continually be triggered for subsequent crossings
                            </span>
                          </label>
                        </LabelDiv>
                        <StyledToggleField
                          id="repeatToggle"
                          value={notification?.geofence?.repeat}
                          onChange={toggleGeofenceRepeat}
                          disabled={!showGeofence}
                        />
                      </StyledInputSection>
                    )}
                  </>
                )}
              </GeofenceSection>
            )}

            <StaggerSection data-testid="staggerSection">
              <StyledToggleField
                id="staggerToggle"
                label="Stagger"
                value={showStagger}
                onChange={toggleStagger}
                disabled={showGeofence}
              />
              {showStagger && (
                <StyledInputSection $disabled={!showStagger} data-testid="StaggerOptionsSection">
                  <LabelDiv>
                    <label htmlFor="staggerInputGroup">
                      <span>
                        Throttle the amount of pushes we do to your segment. Specify intervals (in minutes) of when to
                        push and the batch size of subscribers to push to.
                      </span>
                    </label>
                  </LabelDiv>
                  <div id="staggerInputGroup">
                    <NumberField
                      value={notification?.stagger?.intervals || 0}
                      onChange={handleStaggerChange('intervals')}
                      label="Intervals"
                      incrementor={5}
                      max={60}
                      min={1}
                      disabled={!showStagger}
                      testId="staggerIntervalsInput"
                      suffixString={'mins'}
                    />

                    <NumberField
                      value={notification?.stagger?.batchSize || 0}
                      onChange={handleStaggerChange('batchSize')}
                      label="BatchSize"
                      incrementor={100}
                      max={100000}
                      min={1}
                      disabled={!showStagger}
                      testId="staggerBatchSizeInput"
                    />
                  </div>
                </StyledInputSection>
              )}
            </StaggerSection>
            <StyledDisclaimer>* Geofence and Stagger functionality cannot be used in conjunction</StyledDisclaimer>
          </SchedulingColumnContentDiv>
        </ColumnDiv>
      </Col>
    ),
    [
      status,
      notification,
      notification?.scheduling,
      notification?.geofence,
      notification?.segment,
      notification?.stagger,
      segments,
      loading,
      showSegmentBuilder,
      showGeofence,
      showStagger,
      isNotInAppPage,
      selectedTenant,
      tenantSegmentMap,
      searchText
    ]
  );
};
