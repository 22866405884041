import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';
import { RGBColor } from 'react-color';
import classnames from 'classnames';
import { throttle } from 'throttle-debounce';
import { CONTENT_ITEM_MEDIA_TYPE, CONTENT_ITEM_PRESENTATION_TYPE } from '@pushologies/common/constants/content-item';
import { Fonts, LinkHandling } from '@pushologies/database-service/db/entities/content';
import { ApiContentItem } from '~api/content-items/types';
import { PersonalisationTextField, TilesViewerUploadField } from '~components/inputs';
import { TextField, NumberTimeField, ToggleField, NumberField } from '~components/inputs';
import { ColorPickerField, rgbColorToRgbaString } from '~components/inputs/color-picker-field';
import { useStoreState, useStoreActions } from '~store/hooks';
import { useVideoBuilderContext } from '~contexts/video-builder';
import { VideoContentOptions } from '~store/create-notification/types';
import { FileUploadRef } from '~components/file-upload';
import { constructThumbnailUrl } from '~api/tools/helpers';
import { TileFile } from '~components/file-viewers/tiles';
import { useFileViewerOptions } from '~components/file-viewers/hooks';
import { Button } from '~components/button';
import { notificationCache } from '~store/create-notification/notification-cache';
import { PersonalisationString } from '~components/personalisation-string';
import { SelectOption } from '~components/select';
import { fontOptions, linkHandlingOptions, textAlignmentOptions, textWrappingOptions } from '../../data';
import {
  VideoInfoDiv,
  ActiveButtonSection,
  TwinSection,
  VideoOptionsSection,
  ButtonPreviewDiv,
  StyledSelect,
  StyledTwinSectionWrapper,
  FullWidthSection
} from './styles';
import { INAPP_TYPE } from '@pushologies/common/constants/in-app';
import { useFlags } from 'launchdarkly-react-client-sdk';

const IMAGE_LIMIT = 20;

interface VideoInfoProps {
  closeModal(): void;
}

export const VideoInfo: React.FC<VideoInfoProps> = ({ closeModal }) => {
  const theme = useTheme();
  const { notification } = useStoreState((state) => state.createNotification);
  const { setVideoContent, restoreNotificationCopy, setButton } = useStoreActions((state) => state.createNotification);
  const { uploadItems, fetchItems, fetchItem } = useStoreActions((state) => state.contentItems);
  const { items } = useStoreState((state) => state.contentItems);
  const fileUploadRef = React.useRef<FileUploadRef>();
  const vbContext = useVideoBuilderContext();
  const [buttonContentItems, setButtonContentItems] = React.useState<ApiContentItem[]>([]);
  const {
    hideTextAlignment,
    hideLinkHandlingTags,
    hideVideoEmbeddedPlayerAdminUi,
    hideVideoPreviewAdminUi,
    hideVideoSharingAdminUi
  } = useFlags();
  const { options, setOptions } = useFileViewerOptions();
  const activeButton = notification.buttons[vbContext.activeButtonId];
  const buttonImage = items[activeButton?.options?.contentItemId];
  const buttonFiles: TileFile[] = useMemo(
    () =>
      buttonContentItems.map((item) => ({
        id: item.id,
        name: item.name,
        size: item.size,
        thumbnailUrl: item.downloadUrl || constructThumbnailUrl(item)
      })),
    [buttonContentItems]
  );

  const computedLinkHandlingOptions = useMemo(() => {
    const availableOptions = hideLinkHandlingTags
      ? linkHandlingOptions.filter((eachOption) => !eachOption.name.includes('Tag'))
      : linkHandlingOptions;

    return notification?.inApp?.type === INAPP_TYPE.VIDEO
      ? availableOptions.filter((eachOption) => !eachOption.name.includes('In-App'))
      : availableOptions;
  }, [notification, hideLinkHandlingTags]);

  const handleChangeTime = (key: 'buttonAppear' | 'buttonDisappear') => (seconds: number) => {
    vbContext.updateButtonDuration(activeButton.id, seconds, key);
  };

  const handleTextChange = (text: string) => {
    vbContext.updateButtonText(activeButton.id, text);
  };

  const handleUrlChange = (url: string) => {
    vbContext.updateButtonUrl(activeButton.id, url);
  };

  const handleLinkHandlingChange = (linkHandling: SelectOption<LinkHandling>[]) => {
    setButton({ id: activeButton.id, options: { linkHandling: linkHandling[0].value, tagName: undefined } });
  };

  const handleFontChange = (font: SelectOption<Fonts>[]) => {
    vbContext.updateButtonFont(activeButton.id, font[0].value);
  };

  const handleFontSizeChange = (fontSize: number) => {
    vbContext.updateButtonFontSize(activeButton.id, fontSize);
  };

  const handleTextAlignmentChange = (textAlignment: SelectOption<string>[]) => {
    const [textAlignmentVertical, textAlignmentHorizontal] = (textAlignment[0].value as any).split('-');
    vbContext.updateButtonTextAlignment(activeButton.id, textAlignmentVertical, textAlignmentHorizontal);
  };

  const handleTextWrappingChange = (textWrapping: SelectOption<boolean>[]) => {
    vbContext.updateButtonTextWrapping(activeButton.id, textWrapping[0].value);
  };

  const handleTextColorChange = (color: RGBColor) => {
    vbContext.updateButtonTextColor(activeButton.id, rgbColorToRgbaString(color));
  };

  const handleBackgroundColorChange = (color: RGBColor) => {
    vbContext.updateButtonBackgroundColor(activeButton.id, rgbColorToRgbaString(color));
  };

  const handleBorderColorChange = (color: RGBColor) => {
    vbContext.updateButtonBorderColor(activeButton.id, rgbColorToRgbaString(color));
  };

  const handleOptionChange = (key: keyof VideoContentOptions) => (checked: boolean) => {
    setVideoContent({
      options: { [key]: checked }
    });
  };

  const handlePositionChange = (key: 'x' | 'y') => (value: number) => {
    vbContext.updateButtonPosition(activeButton.id, { [key]: value });
  };

  const handleDisplayNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    vbContext.updateButtonDisplayName(activeButton.id, event.target.value);
  };

  const handleTagNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setButton({ id: activeButton.id, options: { tagName: event.target.value } });
  };

  const handleButtonImageUpload = (files: File[]) => {
    fileUploadRef.current.setLoading(true);
    uploadItems({
      files,
      mediaType: CONTENT_ITEM_MEDIA_TYPE.IMAGE,
      presentation: [CONTENT_ITEM_PRESENTATION_TYPE.BUTTON],
      onProgress: fileUploadRef.current.setLoadingPercentage,
      onSuccess([item]) {
        fetchItem({
          id: item.id,
          url: true,
          onSuccess(contentItem) {
            vbContext.updateButtonImage(activeButton.id, contentItem);
            setButtonContentItems([contentItem, ...buttonContentItems]);
            fileUploadRef.current.reset();
          }
        });
      },
      onError() {
        fileUploadRef.current.setLoading(false);
      }
    });
  };

  const handleButtonImageSelect = ([id]: string[] = []) => {
    // allow for de-selction
    if (!id) {
      return vbContext.clearButtonImage(activeButton);
    }
    fetchItem({
      id,
      url: true,
      onSuccess(contentItem) {
        vbContext.updateButtonImage(activeButton.id, contentItem);
      }
    });
  };

  const handleCancel = () => {
    vbContext.clearCanvas();
    restoreNotificationCopy(notificationCache.getNotification());
    closeModal();
  };

  const handleSave = () => {
    vbContext.clearCanvas();
    closeModal();
  };

  const handlePageChange = (offset: number) => {
    setOptions({ offset });
    fetchItems({
      limit: IMAGE_LIMIT,
      offset,
      name: options.text,
      presentation: [CONTENT_ITEM_PRESENTATION_TYPE.BUTTON],
      onSuccess(btnItems, totalItems) {
        setButtonContentItems(btnItems);
        setOptions({ totalItems });
      }
    });
  };

  const handleFilterValueChange = (text: string) => {
    setOptions({ text });
    throttle(250, () => {
      fetchItems({
        limit: IMAGE_LIMIT,
        offset: options.offset,
        name: text,
        presentation: [CONTENT_ITEM_PRESENTATION_TYPE.BUTTON],
        onSuccess(btnItems, totalItems) {
          setButtonContentItems(btnItems);
          setOptions({ totalItems });
        }
      });
    })();
  };

  const handleExternalContentAdded = () => {
    fetchItems({
      limit: IMAGE_LIMIT,
      presentation: [CONTENT_ITEM_PRESENTATION_TYPE.BUTTON],
      onSuccess(updatedItems, totalItems) {
        setOptions({ totalItems });
        setButtonContentItems(updatedItems);
      }
    });
  };

  const addToButtonItems = (newItems: ApiContentItem[]) => {
    setButtonContentItems((exisitingItems) => [...newItems, ...exisitingItems]);
  };

  React.useEffect(() => {
    fetchItems({
      presentation: [CONTENT_ITEM_PRESENTATION_TYPE.BUTTON],
      onSuccess(btnItems) {
        setButtonContentItems(btnItems);
      }
    });
    notificationCache.setNotification(notification);

    return () => {
      notificationCache.clearCache();
    };
  }, []);

  return (
    <VideoInfoDiv>
      <ActiveButtonSection className={classnames({ disabled: !activeButton })}>
        <div className="inputFieldsWrapper" data-tour="buttonOptions">
          <TextField
            name="displayName"
            label="Button Name:"
            value={activeButton?.displayName}
            onChange={handleDisplayNameChange}
          />
          <PersonalisationTextField
            id="url"
            value={activeButton?.options?.url}
            label="Url:"
            onChange={handleUrlChange}
          />
          <PersonalisationTextField
            id="text"
            value={activeButton?.options?.text}
            label="Text:"
            onChange={handleTextChange}
            disabled={!!activeButton?.options?.contentItemId}
          />
          <StyledSelect
            id="fontSelect"
            label="Font:"
            options={fontOptions}
            value={fontOptions.filter(({ value }) => value === activeButton?.options?.font) ?? [fontOptions[0]]}
            onChange={handleFontChange}
            disabled={!!activeButton?.options?.contentItemId}
          />
          <NumberField
            testId="fontSize"
            label="Font Size:"
            value={activeButton?.options.fontSize}
            onChange={handleFontSizeChange}
            disabled={!!activeButton?.options?.contentItemId}
          />
          <ColorPickerField
            disabled={!!activeButton?.options?.contentItemId}
            testId="textColorPicker"
            label="Text Color:"
            value={activeButton?.options?.textColor}
            onChange={handleTextColorChange}
          />
          <ColorPickerField
            testId="backgroundColorPicker"
            label="Button Color:"
            disabled={!!activeButton?.options?.contentItemId}
            value={activeButton?.options?.backgroundColor}
            onChange={handleBackgroundColorChange}
          />
          <ColorPickerField
            testId="borderColorPicker"
            label="Button Border:"
            value={activeButton?.options?.borderColor}
            onChange={handleBorderColorChange}
          />
          <TwinSection>
            <NumberTimeField
              testId="buttonAppearInput"
              label="In:"
              value={activeButton?.options?.buttonAppear}
              onChange={handleChangeTime('buttonAppear')}
            />
            <NumberTimeField
              testId="buttonDisappearInput"
              label="Out:"
              value={activeButton?.options?.buttonDisappear}
              onChange={handleChangeTime('buttonDisappear')}
            />
          </TwinSection>
          <TwinSection>
            <NumberField
              testId="btnXPositionInput"
              label="Pos. X:"
              value={activeButton?.options?.position?.left || 0}
              onChange={handlePositionChange('x')}
            />
            <NumberField
              testId="btnYPositionInput"
              label="Pos. Y:"
              value={activeButton?.options?.position?.top || 0}
              onChange={handlePositionChange('y')}
            />
          </TwinSection>

          <FullWidthSection>
            <TilesViewerUploadField
              testId="buttonImageField"
              label="Image:"
              uploadButtonText="Create New Button Image"
              fileUploadLabel="Drag button file here"
              uploadPresetExternalFields={{
                mediaType: CONTENT_ITEM_MEDIA_TYPE.IMAGE,
                presentation: [CONTENT_ITEM_PRESENTATION_TYPE.BUTTON]
              }}
              uploadDisableExternalFields={['mediaType', 'presentation']}
              title="Button Images"
              acceptedFiles="image/*"
              files={buttonFiles}
              value={buttonImage?.name}
              onUpload={handleButtonImageUpload}
              uploadMultiple={false}
              onSelect={handleButtonImageSelect}
              ref={fileUploadRef}
              placement={notification?.videoContent?.options?.orientation === 'portrait' ? 'bottom-left' : 'top-right'}
              filterValue={options.text}
              filterPlaceholder="Filter images by name"
              onFilterValueChange={handleFilterValueChange}
              pagination={{
                offset: options.offset,
                totalItems: options.totalItems,
                limit: IMAGE_LIMIT,
                onPageChange: handlePageChange
              }}
              onExternalContentAdded={handleExternalContentAdded}
              presentation={[CONTENT_ITEM_PRESENTATION_TYPE.BUTTON]}
              onImport={addToButtonItems}
            />{' '}
          </FullWidthSection>

          {activeButton?.options?.linkHandling?.endsWith('WithTag') ? (
            <StyledTwinSectionWrapper>
              <div>Link-handling with tags supported in SDK 4.9.0 and above</div>
              <TwinSection>
                <StyledSelect
                  id="linkHandling"
                  label="Link handling:"
                  options={computedLinkHandlingOptions}
                  value={
                    linkHandlingOptions.filter(({ value }) => value === activeButton?.options?.linkHandling) ?? [
                      linkHandlingOptions[0]
                    ]
                  }
                  onChange={handleLinkHandlingChange}
                />

                <TextField
                  name="tagName"
                  label="Tag Name:"
                  value={activeButton?.options?.tagName}
                  onChange={handleTagNameChange}
                  disabled={!activeButton?.options?.linkHandling?.endsWith('WithTag')}
                  invalid={!activeButton?.options?.tagName}
                />
              </TwinSection>
            </StyledTwinSectionWrapper>
          ) : (
            <FullWidthSection>
              <StyledSelect
                id="linkHandling"
                label="Link handling:"
                options={computedLinkHandlingOptions}
                value={
                  linkHandlingOptions.filter(({ value }) => value === activeButton?.options?.linkHandling) ?? [
                    linkHandlingOptions[0]
                  ]
                }
                onChange={handleLinkHandlingChange}
              />
            </FullWidthSection>
          )}

          {!hideTextAlignment && (
            <StyledTwinSectionWrapper>
              <div>Alignment/wrapping supported in SDK 4.8.0 and above</div>
              <TwinSection>
                <StyledSelect
                  id="alignmentSelect"
                  label="Alignment:"
                  options={textAlignmentOptions}
                  value={
                    textAlignmentOptions.filter(
                      ({ value }) =>
                        value.toLowerCase() ===
                        `${activeButton?.options?.textAlignmentVertical}-${activeButton?.options?.textAlignmentHorizontal}`
                    ) ?? [textAlignmentOptions[3]]
                  }
                  onChange={handleTextAlignmentChange}
                  disabled={!!activeButton?.options?.contentItemId}
                />
                <StyledSelect
                  id="wrappingSelect"
                  label="Wrapping:"
                  options={textWrappingOptions}
                  value={
                    textWrappingOptions.filter(({ value }) => value === activeButton?.options?.textWrapping) ?? [
                      textWrappingOptions[0]
                    ]
                  }
                  onChange={handleTextWrappingChange}
                  disabled={!!activeButton?.options?.contentItemId}
                />
              </TwinSection>
            </StyledTwinSectionWrapper>
          )}
        </div>
        <ButtonPreviewDiv $button={activeButton}>
          <p className="title">Button Preview</p>
          <p className="name">{activeButton?.displayName}</p>
          <figure>
            <span className="width">{activeButton?.width || 0}px</span>
            <span className="height">{activeButton?.height || 0}px</span>
            <img
              key={activeButton?.options?.contentItemId}
              src={activeButton?.options?.buttonImageUrl}
              alt="button preview"
            />
            <PersonalisationString
              as="section"
              text={activeButton?.options?.text}
              personalisation={notification.personalisation}
              primaryColour={theme.palette.primaryMustard}
            />
          </figure>
        </ButtonPreviewDiv>
      </ActiveButtonSection>
      <VideoOptionsSection>
        <div data-tour="videoInfoOptions">
          {!hideVideoPreviewAdminUi && (
            <ToggleField
              id="allowPreview"
              label="Allow Preview (ios only)"
              value={notification?.videoContent?.options?.allowPreview}
              onChange={handleOptionChange('allowPreview')}
            />
          )}
          {!hideVideoSharingAdminUi && (
            <ToggleField
              id="allowSharing"
              label="Allow Sharing"
              value={notification?.videoContent?.options?.allowSharing}
              onChange={handleOptionChange('allowSharing')}
            />
          )}
          {!hideVideoEmbeddedPlayerAdminUi && (
            <ToggleField
              id="useEmbeddedPlayer"
              label="Use Embedded Player"
              value={notification?.videoContent?.options?.useEmbeddedPlayer}
              onChange={handleOptionChange('useEmbeddedPlayer')}
            />
          )}
        </div>
        <div>
          <Button testId="videoBuilderCancel" onClick={handleCancel}>
            Cancel
          </Button>
          <Button testId="videoBuilderSave" onClick={handleSave}>
            Save
          </Button>
        </div>
      </VideoOptionsSection>
    </VideoInfoDiv>
  );
};
