import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { ListViewer } from '~components/file-viewers/list';
import { StyledTr, TableSection } from '~components/file-viewers/list/styles';
import { InputDiv } from '~components/file-viewers/styles';
import { ContentItemsFileViewer } from '~components/content-items-viewer';
import { ViewerDiv } from '~components/file-viewers/tiles/styles';
import { ProviderItemListDiv } from '~components/content-items-viewer/styles';

export const StyledFileViewer = styled(ListViewer)`
  box-shadow: -0.1rem 0.1rem 1rem 0.1rem rgba(2, 3, 2, 0.2);

  ${TableSection} {
    min-height: 300px;
  }

  ${StyledTr} {
    background: white;

    &.isSelected {
      background: ${({ theme }) => transparentize(0.2, theme.palette.primaryPlum)};
    }

    td:last-child {
      padding: 0;
    }
  }
`;

export const StyledContentItemsFileViewer = styled(ContentItemsFileViewer)`
  ${InputDiv} {
    min-width: 10rem;
  }

  ${ViewerDiv} {
    height: 36vh;
  }

  ${ProviderItemListDiv} {
    height: 35vh;
  }
`;

interface ButtonViewerHeaderDivProps {
  disabled: boolean;
}

export const ButtonViewerHeaderDiv = styled.div<ButtonViewerHeaderDivProps>`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0.25rem 0;

  span {
    position: absolute;
    right: 0;
    width: 1rem;
    cursor: pointer;

    svg {
      width: 100%;

      path {
        fill: white;
      }
    }

    &:hover {
      svg {
        path {
          fill: ${({ theme }) => theme.palette.primaryPlum};
        }
      }
    }

    ${({ disabled, theme }) =>
      disabled &&
      css`
        cursor: default;
        pointer-events: none;

        svg path {
          fill: ${theme.palette.lightGrey};
        }
      `}
  }
`;

export const ActionIconSpan = styled.span`
  position: relative;
  display: inline-block;
  width: 1rem;
  padding: 0 0.5rem;
  box-sizing: content-box;

  svg {
    width: 100%;

    path {
      fill: ${({ theme }) => theme.palette.darkGrey};
    }
  }
`;

export const UrlSpan = styled.span`
  font-size: 0.75rem;
`;
