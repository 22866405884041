import { CONTENT_ITEM_MEDIA_TYPE, CONTENT_ITEM_PRESENTATION_TYPE } from '@pushologies/common/constants/content-item';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { TilesViewerUploadField } from '~components/inputs';
import { TileFile } from '~components/file-viewers/tiles';
import { constructThumbnailUrl } from '~api/tools/helpers';
import { ApiContentItem } from '~api/content-items/types';
import { useStoreState, useStoreActions } from '~store/hooks';
import { throttle } from 'throttle-debounce';
import { FileUploadRef } from '~components/file-upload';
import { useFileViewerOptions } from '~components/file-viewers/hooks';

const IMAGE_LIMIT = 20;

interface Props {
  testId: string;
  selectedId: string;
  title: string;
  label: string;
  uploadButtonText: string;
  fileUploadLabel: string;
  onChange: (contentItem: ApiContentItem) => void;
}

export const PollImageUpload: FC<Props> = ({
  testId,
  selectedId,
  title,
  label,
  uploadButtonText,
  fileUploadLabel,
  onChange
}) => {
  const fileUploadRef = React.useRef<FileUploadRef>();
  const { items } = useStoreState((state) => state.contentItems);
  const { uploadItems, fetchItems, fetchItem } = useStoreActions((state) => state.contentItems);
  const { options, setOptions } = useFileViewerOptions();

  const [backgroundImageContentItems, setBackgroundImageContentItems] = useState<ApiContentItem[]>([]);
  const selectedImage = items[selectedId];
  const backgroundImageContentFiles: TileFile[] = useMemo(
    () =>
      backgroundImageContentItems.map((item) => ({
        id: item.id,
        name: item.name,
        size: item.size,
        thumbnailUrl: item.downloadUrl || constructThumbnailUrl(item)
      })),
    [backgroundImageContentItems]
  );

  const handleBackgroundImageUpload = (files: File[]) => {
    fileUploadRef.current.setLoading(true);
    uploadItems({
      files,
      mediaType: CONTENT_ITEM_MEDIA_TYPE.IMAGE,
      presentation: [CONTENT_ITEM_PRESENTATION_TYPE.BACKGROUND_IMAGE],
      onProgress: fileUploadRef.current.setLoadingPercentage,
      onSuccess([item]) {
        fetchItem({
          id: item.id,
          url: true,
          onSuccess(contentItem) {
            setBackgroundImageContentItems([contentItem, ...backgroundImageContentItems]);
            fileUploadRef.current.reset();
          }
        });
      },
      onError() {
        fileUploadRef.current.setLoading(false);
      }
    });
  };
  const handleBackgroundImageSelect = ([id]: string[] = []) => {
    fetchItem({
      id,
      url: true,
      onSuccess(contentItem) {
        onChange(contentItem);
      }
    });
  };
  const handleExternalContentAdded = () =>
    fetchItems({
      limit: IMAGE_LIMIT,
      presentation: [CONTENT_ITEM_PRESENTATION_TYPE.BACKGROUND_IMAGE],
      onSuccess(updatedBackgroundImageItems, totalItems) {
        setOptions({ totalItems });
        setBackgroundImageContentItems(updatedBackgroundImageItems);
      }
    });
  const handlePageChange = (offset: number) => {
    setOptions({ offset });
    fetchItems({
      limit: IMAGE_LIMIT,
      offset,
      name: options.text,
      presentation: [CONTENT_ITEM_PRESENTATION_TYPE.BUTTON],
      onSuccess(btnItems, totalItems) {
        setBackgroundImageContentItems(btnItems);
        setOptions({ totalItems });
      }
    });
  };
  const handleFilterValueChange = (text: string) => {
    setOptions({ text });
    throttle(250, () => {
      fetchItems({
        limit: IMAGE_LIMIT,
        offset: options.offset,
        name: text,
        presentation: [CONTENT_ITEM_PRESENTATION_TYPE.BACKGROUND_IMAGE],
        onSuccess(backgroundItems, totalItems) {
          setBackgroundImageContentItems(backgroundItems);
          setOptions({ totalItems });
        }
      });
    })();
  };

  const addToImageItems = (newItems: ApiContentItem[]) => {
    setBackgroundImageContentItems((exisitingItems) => [...newItems, ...exisitingItems]);
  };

  useEffect(() => {
    fetchItems({
      limit: IMAGE_LIMIT,
      presentation: [CONTENT_ITEM_PRESENTATION_TYPE.BACKGROUND_IMAGE],
      onSuccess(backgroundImageItems) {
        setBackgroundImageContentItems(backgroundImageItems);
      }
    });
  }, [fetchItems]);

  return (
    <div>
      <TilesViewerUploadField
        testId={testId}
        label={label}
        uploadButtonText={uploadButtonText}
        fileUploadLabel={fileUploadLabel}
        uploadPresetExternalFields={{
          mediaType: CONTENT_ITEM_MEDIA_TYPE.IMAGE,
          presentation: [CONTENT_ITEM_PRESENTATION_TYPE.BACKGROUND_IMAGE]
        }}
        uploadDisableExternalFields={['mediaType', 'presentation']}
        title={title}
        acceptedFiles="image/*"
        files={backgroundImageContentFiles}
        value={selectedImage?.name}
        onUpload={handleBackgroundImageUpload}
        uploadMultiple={false}
        onSelect={handleBackgroundImageSelect}
        ref={fileUploadRef}
        placement={'top-right'}
        filterValue={options.text}
        filterPlaceholder="Filter images by name"
        onFilterValueChange={handleFilterValueChange}
        pagination={{
          offset: options.offset,
          totalItems: options.totalItems,
          limit: IMAGE_LIMIT,
          onPageChange: handlePageChange
        }}
        onExternalContentAdded={handleExternalContentAdded}
        presentation={[CONTENT_ITEM_PRESENTATION_TYPE.BACKGROUND_IMAGE]}
        onImport={addToImageItems}
      />
    </div>
  );
};
