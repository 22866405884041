import React from 'react';
import { CONTENT_ITEM_PRESENTATION_TYPE } from '@pushologies/common/constants/content-item';
import { useStoreActions, useStoreState } from '~store/hooks';
import { TilesViewerUploadProps } from '~components/file-viewers/tiles-upload';
import { FileUpload, FileUploadRef } from '~components/file-upload';
import { UploadWrapperDiv } from '~components/file-viewers/tiles-upload/styles';
import { ApiContentItem } from '~api/content-items/types';
import { Props as TilesViewerProps, TilesViewer } from '../file-viewers/tiles';
import { ProviderViewer } from './provider';
import { TabsViewer, Tab } from '../file-viewers/tabs';
import { ContentItemsViewerSection } from './styles';

type TabKey = 'externalSource' | 'contentItems';
export interface Props<T> {
  tilesViewer: TilesViewerProps<T>;
  tilesViewerUpload?: Omit<TilesViewerUploadProps, 'title'>;
  presentation: CONTENT_ITEM_PRESENTATION_TYPE[];
  onImport(items: ApiContentItem[]): void;
  testId?: string;
  className?: string;
}

export const ContentItemsFileViewer = React.forwardRef(
  (props: React.PropsWithChildren<Props<any>>, fileUploadRef: React.ForwardedRef<FileUploadRef>) => {
    const [tabKey, setTabKey] = React.useState<TabKey>('contentItems');
    const { fetchProviders } = useStoreActions((state) => state.contentItems);
    const { providers, activeProviderId } = useStoreState((state) => state.contentItems);

    const handleTabChange = (key: string) => {
      setTabKey(key as TabKey);
    };

    React.useEffect(() => {
      if (!providers.length) {
        fetchProviders();
      }
    }, []);

    const contentItemManagementUI = (
      <>
        {props.tilesViewerUpload && (
          <UploadWrapperDiv>
            <FileUpload
              buttonText={props.tilesViewerUpload.uploadButtonText}
              ref={fileUploadRef}
              label={props.tilesViewerUpload.fileUploadLabel}
              dropzoneOptions={{
                onDrop: props.tilesViewerUpload.onUpload,
                accept: props.tilesViewerUpload.acceptedFiles,
                multiple: props.tilesViewerUpload.uploadMultiple
              }}
              presetExternalFields={props.tilesViewerUpload.uploadPresetExternalFields}
              disabledExternalFields={props.tilesViewerUpload.uploadDisableExternalFields}
              onExternalContentAdded={props.tilesViewerUpload.onExternalContentAdded}
            />
          </UploadWrapperDiv>
        )}
        <TilesViewer {...props.tilesViewer} testId={`${props.testId}Tiles`} />
      </>
    );

    return (
      <ContentItemsViewerSection className={props.className} data-testid={props.testId}>
        {!!activeProviderId ? (
          <TabsViewer activeKey={tabKey} onSelect={handleTabChange}>
            <Tab eventKey="externalSource" title="External Source">
              <ProviderViewer
                testId={`${props.testId}ExternalProvider`}
                presentation={props.presentation}
                onImport={props.onImport}
              />
            </Tab>
            <Tab eventKey="contentItems" title="Content Item">
              {contentItemManagementUI}
            </Tab>
          </TabsViewer>
        ) : (
          contentItemManagementUI
        )}
      </ContentItemsViewerSection>
    );
  }
);
