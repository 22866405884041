import React, { useMemo, useState } from 'react';
import { Dropdown, DropdownProps } from 'react-bootstrap';
import { StyledDropdown, StyledDropdownMenu, DropdownItem, ClearableSpan } from './styles';

export interface SelectOption<V = any> {
  name: string;
  value: V;
  icon?: any;
}

export interface Props extends Pick<DropdownProps, 'drop'> {
  id: string;
  testId?: string;
  label?: string;
  value: SelectOption[];
  options: SelectOption[];
  multi?: boolean;
  disabled?: boolean;
  placeholder?: string;
  inValid?: boolean;
  clearable?: boolean;
  className?: string;
  onChange(selected: SelectOption[]): void;
}

export const Select: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const SelectedIcon = props?.value?.[0]?.icon;

  const handleClick = (option: SelectOption) => () => {
    let newValue: SelectOption[];

    if (props.multi) {
      const idx = props.value.findIndex((selected) => option.value === selected.value);
      newValue = idx === -1 ? [...props.value, option] : [...props.value.slice(0, idx), ...props.value.slice(idx + 1)];
    } else {
      newValue = [option];
    }

    !props.multi && setIsOpen(false);
    props.onChange(newValue);
  };

  const handleClear = () => {
    if (props.disabled) return;
    props.onChange([]);
  };

  return useMemo(
    () => (
      <StyledDropdown
        $inValid={props.inValid}
        $hasValue={!!props.value.length}
        drop={props.drop}
        className={props.className}
        $clearable={props.clearable}
        show={isOpen}
      >
        <Dropdown.Toggle
          id={props.id}
          data-testid={props.testId || props.id}
          variant="secondary"
          disabled={props.disabled}
          onClick={() => setIsOpen(!isOpen)}
        >
          {SelectedIcon ? (
            <figure className="icon">
              <SelectedIcon />
            </figure>
          ) : (
            ''
          )}
          <span>
            {props.label && <span className="label">{props.label}</span>}
            {props.value.length ? props.value.map(({ name }) => name).join(', ') : props.placeholder}
          </span>
        </Dropdown.Toggle>
        <StyledDropdownMenu data-testid="selectMenu">
          {props.options.map((option) => (
            <DropdownItem
              as="div"
              key={option.name}
              onClick={handleClick(option)}
              $selected={props.value.includes(option)}
              data-testid="selectMenuItem"
            >
              {option.icon && (
                <figure className="icon">
                  <option.icon />
                </figure>
              )}
              {option.name}
            </DropdownItem>
          ))}
          <span className="bottom-caret-placeholder" />
        </StyledDropdownMenu>
        {props.clearable && (
          <ClearableSpan disabled={props.disabled} onClick={handleClear}>
            clear
          </ClearableSpan>
        )}
      </StyledDropdown>
    ),
    [
      props.id,
      props.options,
      props.placeholder,
      props.value,
      props.multi,
      props.drop,
      props.onChange,
      props.inValid,
      isOpen
    ]
  );
};
