import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { InputSection } from '~components/inputs/styles';
import { StyledDropdown } from '~components/select/styles';
import { TableSection } from '~components/styled/table-section';

const topPadding = '1.5rem';
const topPaddings = `calc(${topPadding} * 3)`;
const inputSectionHeight = '1.75rem';

export const TenantSelectDiv = styled.div`
  padding: ${topPadding} 4rem;
  background-color: ${({ theme }) => theme.palette.secondaryPurple};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5%;

  h4 {
    color: white;
    width: 50%;
  }
`;

export const StyledRow = styled(Row)`
  padding: ${topPadding} 4rem;

  ${TableSection} {
    .table-responsive {
      max-height: ${(props) => `calc(
        100vh - 2.75rem - (
          ${props.theme.metrics.headerHeight} +
          ${props.theme.metrics.headerOverlayHeight} +
          ${topPaddings} +
          ${inputSectionHeight}
        )
      )`};
    }
  }
`;

export const StyledFiltersRow = styled(Row)`
  justify-content: space-between;
  padding: ${topPadding} 4rem 0;
`;

export const TitleCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  padding-right: 0;

  button {
    height: ${inputSectionHeight};
    margin-right: 1rem;
    padding: 0 2.5rem;
    background: ${({ theme }) => theme.palette.lighterGrey} !important;

    &:hover {
      background: ${({ theme }) => theme.palette.textGrey} !important;
    }
  }

  ${InputSection} {
    flex-grow: 2;
    height: ${inputSectionHeight};
    min-height: unset;
  }
`;

export const SelectsCol = styled(Col)`
  display: flex;
  justify-content: space-between;
  padding: 0;
  height: ${inputSectionHeight};

  ${StyledDropdown} {
    margin-right: 1rem;

    button.dropdown-toggle {
      font-family: EavesRegular;
      font-size: 0.9rem;
      letter-spacing: 0;
    }
  }
`;

export const TypeTd = styled.td`
  text-align: center;

  svg {
    width: 1rem;

    &.notification_carousel_svg__icon {
      width: 1.4rem;
    }

    path {
      fill: ${({ theme }) => theme.palette.textGrey};
    }
  }
`;

interface DeleteSpanProps {
  isDeleting: boolean;
}

export const DeleteSpan = styled.span<DeleteSpanProps>`
  svg {
    opacity: ${({ isDeleting }) => (isDeleting ? 0 : 1)};
  }

  .spinner-border {
    position: absolute;
    top: 0.15rem;
    left: 0;
    margin: 0;
    color: ${({ theme }) => theme.palette.lighterGrey};
  }
`;

export const CreatedByTd = styled.td`
  overflow: hidden !important;

  .spinner-border {
    margin-left: calc(50% - 0.5rem);
    color: ${({ theme }) => theme.palette.lighterGrey};
  }
`;

export const PushedAtTd = styled.td`
  i {
    color: ${({ theme }) => theme.palette.lighterGrey};
  }
`;
