import {
  Entity,
  Column,
  PrimaryGeneratedColumn,
  JoinColumn,
  OneToMany,
  ManyToOne,
  CreateDateColumn,
  Index,
  OneToOne,
  DeleteDateColumn
} from 'typeorm';
import { SegmentRule } from '@pushologies/segments-service/types';
import { Tenant } from './tenant';
import { TenantUser } from './tenant-user';
import { SegmentBucket } from './segment-bucket';
import { Geofence } from './geofence';

export enum SEGMENT_DYNAMISM {
  OneTime = 'one-time',
  Always = 'always'
}

@Entity('segment')
@Index(['id', 'tenant'])
@Index(['tenant', 'isHidden'])
export class Segment {
  @PrimaryGeneratedColumn('uuid')
  id: string;

  @Column({ type: 'text' })
  name: string;

  @Column({ type: 'text', nullable: true })
  description: string;

  @Column()
  tenantId: string;
  @ManyToOne((_) => Tenant, (tenant) => tenant.id, { onDelete: 'CASCADE' })
  @JoinColumn()
  @Index()
  tenant: Tenant;

  @OneToMany((_) => SegmentBucket, (bucket) => bucket.segment, { onDelete: 'CASCADE', nullable: true })
  buckets?: SegmentBucket[];

  @Column({ type: 'simple-json', nullable: true })
  rules?: SegmentRule[];

  @Column({ name: 'dynamism', type: 'enum', enum: SEGMENT_DYNAMISM, default: SEGMENT_DYNAMISM.Always })
  @Index()
  dynamism?: SEGMENT_DYNAMISM;

  @Column({ type: 'boolean', name: 'is_hidden', default: false })
  isHidden: boolean;

  @Column({ nullable: true })
  createdById: string;
  @ManyToOne((_) => TenantUser, { onDelete: 'SET NULL' })
  @JoinColumn()
  @Index()
  createdBy: TenantUser;

  @Column({ nullable: true })
  geofenceId?: string;
  @OneToOne((_) => Geofence, { cascade: true })
  @JoinColumn()
  geofence?: Geofence;

  @CreateDateColumn({ name: 'created_at', type: 'timestamptz' })
  createdAt: Date;

  @DeleteDateColumn({ name: 'deleted_at', type: 'timestamptz' })
  deletedAt?: Date;
}
