import React from 'react';
import { BreadcrumbDiv } from '../styles';

export const ProviderBreadcrumb: React.FC<{ path: string; handleFolderExpand: (path: string) => () => void }> = ({
  path,
  handleFolderExpand
}) => {
  const paths = path.split('/').filter((p) => p);
  const expandFolder = (navPath: string) => {
    handleFolderExpand(navPath)();
  };

  return (
    <BreadcrumbDiv>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          if (paths.length) expandFolder('');
        }}
      >
        /
      </a>
      {paths.map((segment, index) => {
        const pathTo = paths.slice(0, index + 1).join('/');

        return (
          <span key={index}>
            {index !== 0 ? ' / ' : ' '}
            <a
              className={index === paths.length - 1 ? 'disableClick' : 'clickable'}
              onClick={(e) => {
                e.preventDefault();
                expandFolder(pathTo);
              }}
            >
              {segment}
            </a>
          </span>
        );
      })}
    </BreadcrumbDiv>
  );
};
