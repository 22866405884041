import styled from 'styled-components';
import { rgba } from 'polished';
import { StyledFormHeader as Header } from '~components/styled/form';

interface SidePanelDivProps {
  $show: boolean;
}

export const SidePanelDiv = styled.div<SidePanelDivProps>`
  position: fixed;
  visibility: ${({ $show }) => ($show ? 'visible' : 'hidden')};
  opacity: ${({ $show }) => ($show ? 1 : 0)};
  transition: visibility 0s, opacity 0.2s linear;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: ${rgba('black', 0.5)};
  z-index: 200;

  .sidePanel__content {
    position: absolute;
    background: white;
    height: 100vh;
    right: ${({ $show }) => ($show ? 0 : '-50rem')};
    transition: right 0.3s ease-out 0.2s;
    top: 0;

    &__section {
      position: relative;
      height: calc(
        100vh - calc(${({ theme }) => `${theme.metrics.headerHeight} + ${theme.metrics.headerOverlayHeight} + 2.6rem`})
      );
      padding: 0rem 1rem;
    }
  }
`;

export const StyledFormHeader = styled(Header)`
  padding-top: calc(${({ theme }) => `${theme.metrics.headerHeight} + ${theme.metrics.headerOverlayHeight} + 0.5rem`});
  padding-bottom: 0.4rem;
  margin-bottom: 0;
  height: unset !important;

  button {
    position: absolute;
    right: 0;
    bottom: 0;
    top: unset;
    font-size: 2rem;
    width: 2rem;
  }
`;
