import React from 'react';
import { Container } from 'react-bootstrap';
import { useStoreActions, useStoreState } from '~store/hooks';
import { settingsPageLinks } from '~pages/settings/links';
import S3Icon from '~images/aws-s3-logo.png';
import { ApiContentProvider } from '~api/content-items/types';
import dayjs from '~helpers/dayjs';
import { Loader } from '~components/loader';
import { Button } from '~components/button';
import { StyledProviderSection } from './styles';
import { AddProviderModal } from './add-provider';

export const Content: React.FC = () => {
  const { setSideNavModel } = useStoreActions((state) => state.sideNav);
  const [providers, setProviders] = React.useState<ApiContentProvider[]>([]);
  const { fetchProviders, setModel } = useStoreActions((state) => state.contentItems);
  const { status, activeProviderId } = useStoreState((state) => state.contentItems);
  const [showModal, setShowModal] = React.useState(false);

  const handleCheckedChange = (provider: ApiContentProvider) => (_: React.ChangeEvent<HTMLInputElement>) => {
    setModel({ activeProviderId: provider._id });
  };

  const addProvider = (provider: ApiContentProvider) => {
    setProviders((state) => [provider, ...state]);
  };

  React.useEffect(() => {
    setSideNavModel({
      topBarLeftTitle: 'Content',
      activeChild: 'content',
      links: settingsPageLinks
    });
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  React.useEffect(() => {
    fetchProviders({
      onSuccess(prov) {
        setProviders(prov);
      }
    });
  }, []);

  return (
    <Container id="page-container">
      <StyledProviderSection>
        <Button onClick={() => setShowModal(true)} className="addNewBtn">
          Add New
        </Button>
        <div className="provider-list">
          <Loader loading={status === 'fetchingProviders'} />
          {!providers?.length && status === 'idle' && <div>No third party content providers saved</div>}
          {providers.map((provider) => {
            return (
              <div className="provider-item" key={'item-' + provider.name}>
                <img src={S3Icon} alt="S3 icon" />
                <div className="provider-item-meta">
                  <h5>{provider.name}</h5>
                  <span>Created at: {dayjs(provider.createdAt).format('HH:mm:ss - DD/MM/YY')}</span>
                  <span>Bucket name: {provider.bucketName}</span>
                </div>
                <input
                  type="checkbox"
                  checked={activeProviderId === provider._id}
                  onChange={handleCheckedChange(provider)}
                />
              </div>
            );
          })}
        </div>
        <AddProviderModal show={showModal} closeModal={handleCloseModal} addToProviderState={addProvider} />
      </StyledProviderSection>
    </Container>
  );
};
