import React, { ReactNode } from 'react';
import { NOTIFICATION_TYPE } from '@pushologies/database-service/db/entities/notification';
import dayjs from '~helpers/dayjs';
import { SelectOption } from '~components/select';
import LogoSmall from '~images/logo_sm.svg';
import { useStoreActions, useStoreState } from '~store/hooks';
import { CarouselContent } from '~store/create-notification/types';
import { PersonalisationString } from '../personalisation-string';
import {
  CarouselButton,
  CarouselImageDiv,
  DateDiv,
  DeviceWrapperDiv,
  InAppNotificationDiv,
  Platform,
  StandardNotificationDiv
} from './styles';
import InAppBackground from '~images/in-app-background.jpg';
import { IN_APP_ALIGNMENT_OPTIONS, InApp, INAPP_TYPE } from '@pushologies/common/constants/in-app';
import { CONTENT_ITEM_PRESENTATION_TYPE } from '@pushologies/common/constants/content-item';

const deviceCases: Record<string, ReactNode> = {
  samsung_note: require('../../assets/images/samsung_note_portrait.svg').default(),
  apple_12: require('../../assets/images/iphone_12.svg').default()
};
export interface DeviceOption extends SelectOption {
  platform: Platform;
}
export const deviceOptions: DeviceOption[] = [
  {
    name: 'Samsung Note',
    value: 'samsung_note',
    platform: 'android'
  },
  {
    name: 'Apple 12',
    value: 'apple_12',
    platform: 'ios'
  }
];

const ScreenContentWrapper: React.FC<
  React.PropsWithChildren<{
    selectedDevice: DeviceOption;
    notificationType?: NOTIFICATION_TYPE;
  }>
> = ({ selectedDevice, notificationType, children }) => {
  const DeviceCase = deviceCases[selectedDevice.value];

  return (
    <DeviceWrapperDiv $type={notificationType} $platform={selectedDevice.platform}>
      <figure className="device">
        {DeviceCase}
        <section data-testid="devicePreviewNotification">{children}</section>
      </figure>
    </DeviceWrapperDiv>
  );
};

interface Props {
  notificationType: NOTIFICATION_TYPE;
  selectedDevice: DeviceOption;
  title: string;
  subTitle?: string;
  message: string;
  bannerUrl?: string;
  videoThumbnailUrl?: string;
  carouselContent: CarouselContent[];
  inApp?: InApp;
  tenantId?: string;
  presentation?: CONTENT_ITEM_PRESENTATION_TYPE;
}

const TypeCarousel: React.FC<Pick<Props, 'carouselContent' | 'selectedDevice' | 'tenantId' | 'title'>> = (props) => {
  const now = dayjs();
  const [activeItem, setActiveItem] = React.useState(0);
  const { items } = useStoreState((state) => state.contentItems);
  const { fetchItem } = useStoreActions((state) => state.contentItems);
  const { notification } = useStoreState((state) => state.createNotification);

  const handleButtonClick = (type: 'next' | 'previous') => () => {
    if (!props.carouselContent.length) return;

    let value = type === 'next' ? activeItem + 1 : activeItem - 1;
    if (value < 0) value = props.carouselContent.length - 1;
    if (value === props.carouselContent.length) value = 0;
    setActiveItem(value);
  };

  React.useEffect(() => {
    if (props.carouselContent[activeItem])
      fetchItem({
        id: props.carouselContent[activeItem].contentItem.id,
        tenantId: props.tenantId ? props.tenantId : null,
        url: true
      });
  }, [activeItem]);

  return (
    <ScreenContentWrapper selectedDevice={props.selectedDevice} notificationType={NOTIFICATION_TYPE.CAROUSEL}>
      <DateDiv>
        <h1>{now.format('HH:mm')}</h1>
        <p>{now.format('dddd, Do MMMM')}</p>
      </DateDiv>
      <StandardNotificationDiv $platform={props.selectedDevice.platform}>
        <article>
          <section>
            <LogoSmall />
            <PersonalisationString as="h4" text={props.title} personalisation={notification.personalisation} />
            <p>now</p>
          </section>
          <CarouselImageDiv url={items[props.carouselContent[activeItem]?.contentItem?.id]?.downloadUrl} />
        </article>
      </StandardNotificationDiv>
      <CarouselButton onClick={handleButtonClick('next')}>Next</CarouselButton>
      <CarouselButton onClick={handleButtonClick('previous')}>Previous</CarouselButton>
    </ScreenContentWrapper>
  );
};

const TypeStandard: React.FC<
  Pick<Props, 'selectedDevice' | 'bannerUrl' | 'title' | 'subTitle' | 'message' | 'presentation'>
> = (props) => {
  const { notification } = useStoreState((state) => state.createNotification);
  const now = dayjs();

  return (
    <ScreenContentWrapper selectedDevice={props.selectedDevice} notificationType={NOTIFICATION_TYPE.STANDARD}>
      <DateDiv>
        <h1>{now.format('HH:mm')}</h1>
        <p>{now.format('dddd, Do MMMM')}</p>
      </DateDiv>
      <StandardNotificationDiv $platform={props.selectedDevice.platform}>
        {props.selectedDevice.platform === 'android' && props.bannerUrl ? (
          props.presentation === CONTENT_ITEM_PRESENTATION_TYPE.RICH_IMAGE ? (
            <div className="android-rich-image-preview">
              <PersonalisationString as="h5" text={props.title} personalisation={notification.personalisation} />
              <PersonalisationString as="h6" text={props.message} personalisation={notification.personalisation} />
              <img src={props.bannerUrl} alt="Rich Image" />
            </div>
          ) : (
            <img src={props.bannerUrl} alt="Banner Image" />
          )
        ) : (
          <article className="ios-preview">
            <section>
              <LogoSmall />
              <PersonalisationString as="h4" text={props.title} personalisation={notification.personalisation} />
              <p>now</p>
            </section>
            {props.selectedDevice.platform === 'ios' && (
              <PersonalisationString as="h5" text={props.subTitle} personalisation={notification.personalisation} />
            )}
            <PersonalisationString as="h6" text={props.message} personalisation={notification.personalisation} />

            {props.presentation === CONTENT_ITEM_PRESENTATION_TYPE.RICH_IMAGE && props.bannerUrl && (
              <img src={props.bannerUrl} alt="Rich Image" />
            )}
          </article>
        )}
      </StandardNotificationDiv>
    </ScreenContentWrapper>
  );
};

const TypeInApp: React.FC<
  Pick<
    Props,
    | 'title'
    | 'message'
    | 'inApp'
    | 'bannerUrl'
    | 'videoThumbnailUrl'
    | 'selectedDevice'
    | 'carouselContent'
    | 'tenantId'
  >
> = (props) => {
  const [activeItem, setActiveItem] = React.useState(0);
  const { items } = useStoreState((state) => state.contentItems);
  const { fetchItem } = useStoreActions((state) => state.contentItems);

  const handleButtonClick = (type: 'next' | 'previous') => () => {
    if (!props.carouselContent.length || !(props.inApp?.type === INAPP_TYPE.CAROUSEL)) return;

    let value = type === 'next' ? activeItem + 1 : activeItem - 1;
    if (value < 0) value = props.carouselContent.length - 1;
    if (value === props.carouselContent.length) value = 0;
    setActiveItem(value);
  };

  React.useEffect(() => {
    if (props.inApp?.type !== INAPP_TYPE.CAROUSEL) return;
    if (props.carouselContent[activeItem])
      fetchItem({
        id: props.carouselContent[activeItem].contentItem.id,
        tenantId: props.tenantId ? props.tenantId : null,
        url: true
      });
  }, [activeItem]);

  return (
    <ScreenContentWrapper
      selectedDevice={props.selectedDevice}
      notificationType={
        props.inApp?.type === INAPP_TYPE.CAROUSEL ? NOTIFICATION_TYPE.CAROUSEL : NOTIFICATION_TYPE.INAPP
      }
    >
      <img src={InAppBackground} />
      <InAppNotificationDiv
        $type={props.inApp?.type}
        $platform={props.selectedDevice.platform}
        $alignment={
          props.inApp?.imageOptions?.alignment ||
          props.inApp?.videoOptions?.alignment ||
          IN_APP_ALIGNMENT_OPTIONS.CENTER
        }
      >
        <div className="message-preview">
          {props.inApp?.type === INAPP_TYPE.STANDARD && (
            <>
              <h1 data-testId={'inAppPreviewTitle'}>{props.title || 'Your title here'}</h1>
              <p data-testId={'inAppPreviewMessage'}>{props.message || 'Your message here'}</p>
              <p>
                <span data-testId={'inAppPreviewClose'}>{props?.inApp?.closeLabel || 'Close'}</span>
                {props?.inApp?.ctaLabel && (
                  <span data-testId={'inAppPreviewCTA'}>{props?.inApp?.ctaLabel || 'OK'}</span>
                )}
              </p>
            </>
          )}
          {props.inApp?.type === INAPP_TYPE.CAROUSEL ? (
            <>
              <StandardNotificationDiv $platform={props.selectedDevice.platform}>
                <article>
                  <CarouselImageDiv url={items[props.carouselContent[activeItem]?.contentItem?.id]?.downloadUrl} />
                </article>
              </StandardNotificationDiv>
              <CarouselButton onClick={handleButtonClick('next')}>Next</CarouselButton>
              <CarouselButton onClick={handleButtonClick('previous')}>Previous</CarouselButton>
            </>
          ) : (
            <img data-testId={'inAppPreviewImage'} src={props?.bannerUrl || props?.videoThumbnailUrl} />
          )}
        </div>
      </InAppNotificationDiv>
    </ScreenContentWrapper>
  );
};

export const PreviewDevice: React.FC<Props> = ({
  notificationType,
  selectedDevice,
  title,
  subTitle,
  message,
  bannerUrl,
  videoThumbnailUrl,
  carouselContent,
  inApp,
  presentation,
  tenantId
}) => {
  const carouselProps = { carouselContent, selectedDevice, title, tenantId };
  const standardProps = { subTitle, message, bannerUrl, presentation, selectedDevice, title };
  const inAppProps = { title, message, inApp, bannerUrl, videoThumbnailUrl, selectedDevice, carouselContent, tenantId };

  switch (notificationType) {
    case NOTIFICATION_TYPE.INAPP:
      return <TypeInApp {...inAppProps} />;
    case NOTIFICATION_TYPE.CAROUSEL:
      return <TypeCarousel {...carouselProps} />;
    default:
      return <TypeStandard {...standardProps} />;
  }
};
