import React from 'react';
import { Spinner } from 'react-bootstrap';
import { StyledButton } from './styles';

interface Props {
  id?: string;
  testId?: string;
  tourId?: string;
  disabled?: boolean;
  loading?: boolean;
  loadingPercentage?: number;
  className?: string;
  variant?: 'primary' | 'secondary';
  onClick(): void;
}
export const Button: React.FC<React.PropsWithChildren<Props>> = (props) => (
  <StyledButton
    className={props.className}
    onClick={props.onClick}
    disabled={props.loading || props.disabled}
    $loading={props.loading}
    $loadingPercentage={props.loadingPercentage}
    data-testid={props.testId || props.id}
    data-tour={props.tourId}
    id={props.id}
    variant={props.variant || 'primary'}
  >
    {props.loading && <Spinner animation="border" size="sm" />}
    <span>{props.children}</span>
  </StyledButton>
);
