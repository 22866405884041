import React from 'react';
import { StyledCheckboxDiv } from './styles';

interface Props {
  id?: string;
  value?: string;
  checked: boolean;
  disabled?: boolean;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
  label?: string;
  className?: string;
}

export const Checkbox: React.FC<Props> = (props) => {
  return (
    <StyledCheckboxDiv className={props.className}>
      <input
        type="checkbox"
        id={props.id}
        value={props.value}
        checked={props.checked}
        onChange={props.onChange}
        disabled={props.disabled}
        readOnly={!props.onChange}
      />
      <label htmlFor={props.id}>{props?.label}</label>
    </StyledCheckboxDiv>
  );
};
