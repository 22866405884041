import { action, persist, thunk } from 'easy-peasy';
import merge from 'deepmerge';
import { ApiContentItem } from '~api/content-items/types';
import { handleUnAuthenticated } from '~store/helpers';
import { arrayToObject } from '~helpers/arrays';
import { ContentItemsModel } from './types';
import { isUrlValid } from './helpers';
import { isFulfilled } from '~helpers/typings';

export * from './types';

export const contentItemsModel: ContentItemsModel = persist(
  {
    status: 'idle',
    items: {},
    providers: [],
    provider: {},
    selectedItemReferences: [],
    error: null,
    activeProviderId: null,

    setModel: action((state, payload) => {
      Object.assign(state, payload);
    }),

    addItem: action((state, payload) => {
      const oldItem = state.items[payload.id];

      state.items[payload.id] = {
        ...oldItem,
        ...payload,
        // preserve local size as s3 upload trigger may not hav updated db size field
        ...(!!oldItem?.size && { size: oldItem.size })
      };
    }),

    addItems: action((state, payload) => {
      state.items = merge(state.items, arrayToObject<ApiContentItem>('id', payload));
    }),

    amendSelectedItemReference: action((state, { itemReference }) => {
      if (state.selectedItemReferences.includes(itemReference)) {
        state.selectedItemReferences = state.selectedItemReferences.filter((item) => item !== itemReference);
      } else {
        state.selectedItemReferences = [...state.selectedItemReferences, itemReference];
      }
    }),

    clearSelectedItemReference: action((state) => {
      state.selectedItemReferences = [];
    }),

    uploadItems: thunk(
      async (
        actions,
        { files, mediaType, presentation, onProgress, onSuccess, onError },
        { injections, getStoreActions }
      ) => {
        actions.setModel({ status: 'uploading' });
        let progress = 0;
        let error: string = null;
        const totalPercentage = files.length * 100;

        const handleOnProgress = (percentage: number) => {
          progress = files.length === 1 ? percentage : percentage + progress;
          onProgress(Math.ceil((progress / totalPercentage) * 100));
        };

        try {
          const results = await Promise.allSettled(
            files.map((file) =>
              injections.contentItemsApi.uploadItem(file, mediaType, presentation, handleOnProgress).then((result) => ({
                ...result.contentItem,
                size: file.size,
                mimeType: file.type,
                downloadUrl: URL.createObjectURL(file),
                thumbnailUrl: URL.createObjectURL(file)
              }))
            )
          );

          const contentItems: ApiContentItem[] = [];
          const errors: string[] = [];
          for (let i = 0; i < results.length; i++) {
            const result = results[i];
            if (isFulfilled(result)) {
              contentItems.push(result.value);
            } else {
              errors.push(`Error uploading ${files[i].name}: ${result.reason.message}`);
            }
          }

          if (contentItems.length) {
            actions.addItems(contentItems);
            onSuccess && onSuccess(contentItems);
            getStoreActions().notificationWidget.addNotification({
              type: 'success',
              message: `${contentItems.length} item(s) were successfully uploaded`
            });
          }

          if (errors.length) {
            onError && onError(errors);
            error = errors.join('.');
            getStoreActions().notificationWidget.addNotification({
              type: 'error',
              message: error
            });
          }

          actions.setModel({ status: 'idle', error });
        } catch (error) {
          await handleUnAuthenticated(error, getStoreActions());

          actions.setModel({ status: 'idle', error: error.message });
          getStoreActions().notificationWidget.addNotification({
            type: 'error',
            message: error.message
          });
          onError && onError([error]);
        }
      }
    ),

    createExternalContentItem: thunk(async (actions, { onError, onSuccess, data }, { injections, getStoreActions }) => {
      actions.setModel({ status: 'creatingExternal' });

      try {
        const { contentItem } = await injections.contentItemsApi.createExternalItem(data);

        actions.addItem(Object.assign(contentItem, data));
        onSuccess && onSuccess(contentItem);

        actions.setModel({ status: 'idle' });
        getStoreActions().notificationWidget.addNotification({
          type: 'success',
          message: `Successfully created content item "${data.name}"`
        });
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());

        actions.setModel({ status: 'idle', error: error.message });
        getStoreActions().notificationWidget.addNotification({
          type: 'error',
          message: error.message
        });
        onError && onError(error);
      }
    }),

    saveProvider: thunk(async (actions, payload, { injections, getStoreActions, getState }) => {
      actions.setModel({ status: 'saving provider' });

      try {
        const data = getState().provider;
        const { provider } = await injections.contentItemsApi.createProvider(data);

        getStoreActions().notificationWidget.addNotification({
          type: 'success',
          message: 'Successfully added third-party Content Provider'
        });

        if (payload && payload.onSuccess) payload.onSuccess(provider);
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());

        getStoreActions().notificationWidget.addNotification({
          type: 'error',
          message: error.message
        });
        actions.setModel({ status: 'idle', error: error.message });

        if (payload && payload.onError) payload.onError(error.message);
      }
    }),

    fetchItem: thunk(async (actions, { id, url, tenantId, onSuccess }, { injections, getStoreActions, getState }) => {
      actions.setModel({ status: 'fetchingOne' });

      const item = getState().items[id];

      // check if downloadUrl is still valid and return if so
      if (item) {
        if (!url || isUrlValid(item?.downloadUrl)) {
          actions.setModel({ status: 'idle' });
          onSuccess && onSuccess(item);
          return;
        }
      }

      try {
        const { contentItem } = await (tenantId
          ? injections.contentItemsApi.fetchItemByProxy(id, tenantId, url)
          : injections.contentItemsApi.fetchItem(id, url));

        actions.addItem(contentItem);
        actions.setModel({ status: 'idle', error: null });

        onSuccess && onSuccess(contentItem);
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());

        actions.setModel({ status: 'idle', error: error.message });
        getStoreActions().notificationWidget.addNotification({
          type: 'error',
          message: error.message
        });
      }
    }),

    fetchItems: thunk(async (actions, { onSuccess, ...params }, { injections, getStoreActions }) => {
      actions.setModel({ status: 'fetching' });

      try {
        const { contentItems, totalItems } = await injections.contentItemsApi.fetchItems(params);

        actions.addItems(contentItems);
        actions.setModel({ status: 'idle', error: null });
        onSuccess && onSuccess(contentItems, totalItems);
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());

        actions.setModel({ status: 'idle', error: error.message });
        getStoreActions().notificationWidget.addNotification({
          type: 'error',
          message: error.message
        });
      }
    }),

    fetchProviders: thunk(async (actions, args, { injections, getStoreActions, getState }) => {
      actions.setModel({ status: 'fetchingProviders' });

      try {
        const { providers } = await injections.contentItemsApi.fetchProviders();

        actions.setModel({
          providers,
          ...(!getState().activeProviderId && providers.length && { activeProviderId: providers[0]._id })
        });

        args && args.onSuccess && args.onSuccess(providers);
        actions.setModel({ status: 'idle' });
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());

        actions.setModel({ status: 'idle', error: error.message });
        getStoreActions().notificationWidget.addNotification({
          type: 'error',
          message: error.message
        });
      }
    }),

    importProviderItems: thunk(async (actions, args, { injections, getStoreActions, getState }) => {
      actions.setModel({ status: 'importingProviderItems' });

      try {
        const { imports } = await injections.contentItemsApi.importContentProviderItems({
          providerId: getState().activeProviderId,
          items: args.items
        });

        args.onSuccess && args.onSuccess(imports);
        actions.setModel({ status: 'idle' });
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());

        actions.setModel({ status: 'idle' });
        args?.onError?.(error.message);
        getStoreActions().notificationWidget.addNotification({
          type: 'error',
          message: error.message
        });
      }
    }),

    fetchProviderItems: thunk(async (actions, { onSuccess, onError, ...params }, { injections, getStoreActions }) => {
      try {
        const { items, metadata } = await injections.contentItemsApi.fetchProviderItems(params);

        onSuccess && onSuccess(items, metadata);
        actions.setModel({ status: 'idle' });
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());

        actions.setModel({ status: 'idle', error: error.message });
        onError && onError(error.message);
        getStoreActions().notificationWidget.addNotification({
          type: 'error',
          message: error.message
        });
      }
    }),

    deleteItems: thunk(async (actions, { ids, onSuccess }, { injections, getStoreActions, getState }) => {
      actions.setModel({ status: 'deleting' });

      try {
        const { success, unableToDelete } = await injections.contentItemsApi.deleteItems(ids);

        actions.setModel({ status: 'idle', error: null });

        if (unableToDelete.length) {
          const undeletedNames = unableToDelete.map((id) => getState().items[id].name).join(',');
          const message = `Unable to delete content items: ${undeletedNames}`;
          getStoreActions().notificationWidget.addNotification({ type: 'error', message });
          actions.setModel({ error: message });
        }

        success && onSuccess && onSuccess();
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());

        actions.setModel({ status: 'idle', error: error.message });
        getStoreActions().notificationWidget.addNotification({
          type: 'error',
          message: error.message
        });
      }
    }),

    updateItem: thunk(async (actions, { onSuccess, onError, id, ...params }, { injections, getStoreActions }) => {
      try {
        const { contentItem } = await injections.contentItemsApi.patchItem(id, params);
        actions.addItem(contentItem);
        onSuccess?.(contentItem);
      } catch (error) {
        await handleUnAuthenticated(error, getStoreActions());

        onError?.(error.message);
        getStoreActions().notificationWidget.addNotification({
          type: 'error',
          message: error.message
        });
      }
    })
  },
  {
    allow: ['items', 'providers', 'activeProviderId'],
    storage: 'localStorage'
  }
);
