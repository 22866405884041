import styled, { css } from 'styled-components';
import { InputSection } from '~components/inputs/styles';
import { ViewerDiv } from '~components/file-viewers/tiles/styles';
import { ColumnContentDiv } from '../styles';
import OptedInIcon from '~images/opted_in.svg';
import { EnlargedScrollbar, HideScrollbars } from '~styles/mixins';
import {
  ContentItemsViewerSection,
  ProviderConfigDiv,
  ProviderItemListDiv
} from '~components/content-items-viewer/styles';
import { TabsSection } from '~components/file-viewers/tabs/styles';
import { Modal, Row } from 'react-bootstrap';

export const NotificationDetailsColumnContentDiv = styled(ColumnContentDiv)`
  ${InputSection} {
    margin-bottom: 0.5rem;

    &:last-child {
      margin: 0;
    }
  }
`;

export const BannerUploadColumnContentDiv = styled(ColumnContentDiv)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  section {
    border: none;
    padding: 0;

    &:hover {
      border: none;
    }

    button {
      height: 3rem;
    }
  }
`;

export const BannerImagesColumnContentDiv = styled(ColumnContentDiv)`
  ${ContentItemsViewerSection} {
    padding: 0;
    border: none;

    ${TabsSection} {
      box-shadow: none;
    }

    ${ViewerDiv} {
      height: 20rem;
    }

    ${ProviderConfigDiv} {
      padding: 0.5rem 0;
    }

    ${ProviderItemListDiv} {
      height: 16rem;
    }
  }
`;

interface BannerPreviewFigureProps {
  $bannerUrl: string;
  label?: string;
  isCarousel?: boolean;
  isRichImage?: boolean;
}

export const BannerPreviewFigure = styled.figure<BannerPreviewFigureProps>`
  position: relative;
  border: 1px solid ${({ theme }) => theme.palette.lightGrey};
  flex-grow: 2;
  margin: 0 0.5rem 0 0;
  height: 4rem;

  ${({ $bannerUrl, isCarousel, theme, isRichImage }) =>
    $bannerUrl
      ? css`
          background: url(${$bannerUrl});
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
        `
      : css`
          &:before {
            content: '${isCarousel || isRichImage ? 'preview' : 'banner preview'}';
            font-family: EavesBold;
            font-size: 1.1rem;
            text-transform: uppercase;
            color: ${theme.palette.lightGrey};
            position: absolute;
            width: 100%;
            text-align: center;
            top: 50%;
            transform: translateY(-50%);
          }
        `}
  ${({ label, theme }) =>
    label &&
    css`
      &:after {
        content: '${label}';
        font-family: EavesBold;
        font-size: 1.1rem;
        text-transform: uppercase;
        color: ${theme.palette.lighterGrey};
        position: absolute;
        width: 30px;
        height: 30px;
        text-align: center;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        transform: translateY(-20%);
        background-color: ${theme.palette.primaryPlum};
      }
      &:before {
        width: 100%;
        text-align: center;
        top: 70%;
      }
    `}

  img {
    height: 100%;
  }
`;

export const PollStatusIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25em;
  ${({ theme }) => css`
    background-color: ${theme.palette.errorSecondary};
    &.valid {
      background-color: transparent;
      border: 1px solid ${theme.palette.successPrimary};
    }
  `}
`;

export const StyledOptedInIcon = styled(OptedInIcon)`
  width: 14px;
  margin-right: 10px;
`;

export const TabContentDiv = styled.div`
  height: 44rem;
  overflow-y: scroll;
  background: ${({ theme }) => theme.palette.faintGrey};

  ${EnlargedScrollbar}

  i {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 1rem;
    color: ${({ theme }) => theme.palette.platinum};
    font-size: 0.95rem;
  }
`;

export const ClientMetadataInputWrapper = styled.div`
  cursor: pointer;
  margin: 5px 0 10px 0;
  section {
    pointer-events: none;
  }
`;

export const StyledClientMetadataModal = styled(Modal)`
  overflow-y: hidden !important;

  .modal-dialog {
    max-width: unset;
    min-height: unset;
    height: 80vh;
    width: 60vw;
    min-width: 980px;

    .modal-content {
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 0;

      .modal-body {
        overflow-y: scroll;
        padding-right: 0;
        padding-left: 0;
        ${HideScrollbars}

        .tab-content {
          padding: 10px;

          .row {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .monaco-editor .editor-widget {
    display: none !important;
    visibility: hidden !important;
  }
`;

export const StyledClientMetadataModalBody = styled(Modal.Body)`
  padding: 20px 40px;
`;

export const ClientMetadataNav = styled(Row)`
  display: flex;
  justify-content: space-between;

  div {
    flex: 1;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.darkGrey};

    h1 {
      font-family: EavesBold;
      font-size: 2.75rem;
      margin: 0;
      color: ${({ theme }) => theme.palette.platinum};
      text-transform: uppercase;

      .personalisation {
        font-size: 1.5rem;
      }
    }

    span.status {
      font-family: EavesBold;
      margin-left: 0.75rem;
      color: ${({ theme }) => theme.palette.primaryPlum};
      text-transform: uppercase;
    }
  }

  .nav {
    .nav-item {
      position: relative;
      flex-grow: 2;
      text-align: center;
      width: 200px;

      * {
        border-right: 1px solid ${({ theme }) => theme.palette.lighterGrey};
      }

      &:last-child {
        border-right: none;
      }

      a {
        width: 100%;
        text-transform: uppercase;
        color: ${({ theme }) => theme.palette.textLightGrey};

        &:hover {
          color: ${({ theme }) => theme.palette.primaryMustard};
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: ${({ theme }) => theme.palette.lighterGrey};
        }

        &.active {
          color: ${({ theme }) => theme.palette.primaryPlum};

          &::after {
            height: 4px;
            background: ${({ theme }) => theme.palette.primaryPlum};
          }
        }
      }
    }
  }

  .tab-pane {
    text-align: center;

    .spinner-border {
      position: relative;
      margin-bottom: 2rem;
      color: ${({ theme }) => theme.palette.darkGrey};
    }
  }
`;

export const StyledClientMetadataInvalidStatus = styled.div`
  color: red;
`;

export const StyledDeleteClientMetadataButton = styled.button`
  background: transparent;
  display: block;
  width: 20px;
  height: 20px;
  padding: 3px;
  border: 0;
`;
