import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { HideScrollbars } from '~styles/mixins';

export const StyledModal = styled(Modal)`
  overflow-y: hidden !important;
  display: flex !important;
  justify-content: center;
  align-items: center;

  .modal-dialog {
    width: 40vw;
    min-width: 550px;

    .modal-content {
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .modal-body {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 2rem;
        overflow-y: scroll;
        max-height: 95vh;
        ${HideScrollbars}

        .container-fluid {
          .row {
            margin: 0;

            div[class^='col-md'] {
              &:first-child {
                padding-left: 0;
              }
            }
          }
        }
      }
    }

    .inputField {
      margin-bottom: 1rem;

      span.description {
        line-height: 0.8rem;
        font-size: 0.8rem;
        margin-left: 0.5rem;
        color: ${({ theme }) => theme.palette.platinum};
      }
      span.disclaimer {
        display: block;
        line-height: 0.5rem;
        font-size: 0.8rem;
        margin-bottom: 0.3rem;
        color: ${({ theme }) => theme.palette.platinum};
      }
    }

    p.error {
      color: ${({ theme }) => theme.palette.errorPrimary};
      margin: 0;
      font-size: 0.9rem;
    }

    .modal-buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      button {
        padding: 0.5rem 2.25rem;
        :first-of-type {
          margin-right: 10px;
        }
      }
    }

    .instructionDiv {
      background-color: rgba(100, 100, 100, 0.1);
      padding: 1rem;
      margin-bottom: 2rem;

      h4 {
        text-transform: uppercase;
        margin: 0;
        font-family: EavesBold;
        font-size: 1.25rem;
      }

      .slide {
        width: 100%;
        span {
          display: block;
          margin: 1rem 0;
        }
        img {
          width: 100%;
          margin: 0.75rem 0;
          border: solid 2px ${({ theme }) => theme.palette.primaryMustard};
        }
        button {
          background-color: ${({ theme }) => theme.palette.primaryMustard};
          margin: 1rem;
          padding: 0.125rem 2rem;
        }
      }
    }
  }
`;

export const StepDiv = styled.div`
  display: none;

  &.show {
    display: block;
  }
`;
