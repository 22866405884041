import React from 'react';
import { throttle } from 'throttle-debounce';
import { CONTENT_ITEM_MEDIA_TYPE, CONTENT_ITEM_PRESENTATION_TYPE } from '@pushologies/common/constants/content-item';
import { FileUpload, FileUploadRef } from '~components/file-upload';
import { useFileViewerOptions } from '~components/file-viewers/hooks';
import { useStoreActions, useStoreState } from '~store/hooks';
import { ApiContentItem } from '~api/content-items/types';
import { Image } from './image';
import { ImageListDiv, StyledContentItemsFileViewer } from './styles';

const IMAGE_LIMIT = 30;

export const ImageList: React.FC = () => {
  const fileUploadRef = React.useRef<FileUploadRef>();
  const { status } = useStoreState((state) => state.contentItems);
  const { uploadItems, fetchItems } = useStoreActions((state) => state.contentItems);
  const [contentItems, setContentItems] = React.useState<ApiContentItem[]>([]);
  const { options, setOptions } = useFileViewerOptions();

  const addToContentItems = (newItems: ApiContentItem[]) => {
    setContentItems((exisitingItems) => [...newItems, ...exisitingItems]);
  };

  const handleImageUpload = async (files: File[]) => {
    fileUploadRef.current.setLoading(true);

    uploadItems({
      files,
      mediaType: CONTENT_ITEM_MEDIA_TYPE.IMAGE,
      presentation: [CONTENT_ITEM_PRESENTATION_TYPE.CAROUSEL],
      onProgress: fileUploadRef.current.setLoadingPercentage,
      onSuccess(items) {
        fileUploadRef.current.reset();
        setContentItems([...items, ...contentItems]);
      }
    });
  };

  const handleExternalContentAdded = () => {
    fetchItems({
      limit: IMAGE_LIMIT,
      presentation: [CONTENT_ITEM_PRESENTATION_TYPE.CAROUSEL],
      onSuccess(items, totalItems) {
        setOptions({ totalItems });
        setContentItems(items);
      }
    });
  };

  const handleImageFilterChange = (text: string) => {
    setOptions({ text });
    throttle(250, () => {
      fetchItems({
        limit: IMAGE_LIMIT,
        offset: options.offset,
        name: text,
        presentation: [CONTENT_ITEM_PRESENTATION_TYPE.CAROUSEL],
        onSuccess(items, totalItems) {
          setOptions({ totalItems });
          setContentItems(items);
        }
      });
    })();
  };

  const handlePageChange = (offset: number) => {
    fetchItems({
      limit: IMAGE_LIMIT,
      offset,
      name: options.text,
      presentation: [CONTENT_ITEM_PRESENTATION_TYPE.CAROUSEL],
      onSuccess(items, totalItems) {
        setOptions({ totalItems, offset });
        setContentItems(items);
      }
    });
  };

  React.useEffect(() => {
    fetchItems({
      limit: IMAGE_LIMIT,
      presentation: [CONTENT_ITEM_PRESENTATION_TYPE.CAROUSEL],
      onSuccess(items, totalItems) {
        setOptions({ totalItems });
        setContentItems(items);
      }
    });
  }, []);

  return (
    <ImageListDiv>
      <FileUpload
        ref={fileUploadRef}
        buttonText="Create New Carousel Image"
        label="Upload new carousel image"
        dropzoneOptions={{
          accept: ['image/*'],
          onDrop: handleImageUpload
        }}
        tourId="carouselImagesUpload"
        presetExternalFields={{
          mediaType: CONTENT_ITEM_MEDIA_TYPE.IMAGE,
          presentation: [CONTENT_ITEM_PRESENTATION_TYPE.CAROUSEL]
        }}
        disabledExternalFields={['mediaType', 'presentation']}
        onExternalContentAdded={handleExternalContentAdded}
      />
      <StyledContentItemsFileViewer
        testId="carouselImagesContentViewer"
        presentation={[CONTENT_ITEM_PRESENTATION_TYPE.CAROUSEL]}
        tilesViewer={{
          tourId: 'carouselImages',
          columns: 3,
          files: contentItems,
          loading: status === 'fetching',
          title: 'Carousel Images',
          renderTile: (item: ApiContentItem) => <Image key={item.id} file={item} />,
          pagination: {
            limit: IMAGE_LIMIT,
            offset: options.offset,
            totalItems: options.totalItems,
            onPageChange: handlePageChange
          },
          filterValue: options.text,
          onFilterValueChange: handleImageFilterChange,
          filterPlaceholder: 'Search By Name'
        }}
        onImport={addToContentItems}
      />
    </ImageListDiv>
  );
};
