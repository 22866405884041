import React from 'react';
import { Toast } from 'react-bootstrap';
import copy from 'copy-to-clipboard';
import CopyPasteIcon from '~images/copy_paste.svg';
import { CopyDiv } from './styles';

interface Props {
  copyText: string;
  copyMessage?: string;
}

export const CopyWidget: React.FC<React.PropsWithChildren<Props>> = ({
  copyText,
  copyMessage = 'copied',
  children
}) => {
  const [show, setShow] = React.useState(false);
  const closeToast = () => setShow(false);
  const handleCopy = () => {
    copy(copyText);
    setShow(true);
  };

  return (
    <CopyDiv>
      {children}
      <div className="copy-icon" onClick={handleCopy}>
        <CopyPasteIcon />
        <Toast show={show} delay={1500} onClose={closeToast} autohide>
          {copyMessage}
        </Toast>
      </div>
    </CopyDiv>
  );
};
