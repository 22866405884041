import styled from 'styled-components';
import { ToggleField } from '~components/inputs';
import { ToggleWrapperDiv } from '~components/toggle-button/styles';

export const StyledProviderSection = styled.section`
  max-width: 70%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2rem auto;

  .addNewBtn {
    margin-bottom: 1.5rem;
  }

  .provider-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .provider-item {
    min-width: 520px;
    padding: 1.5rem;
    border-radius: 0.5rem;
    border: solid 1px ${({ theme }) => theme.palette.primaryPlum};
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    img {
      width: 80px;
    }

    input {
      border: solid 1px red;
    }

    h5 {
      font-family: EavesBold;
      margin-bottom: 0.25rem;
      line-height: 1.5rem;
      color: rgba(10, 10, 10, 0.7);
    }

    span {
      margin: 0;
      line-height: 1rem;
      color: rgba(10, 10, 10, 0.5);
    }

    .provider-item-meta {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const StyledToggleField = styled(ToggleField)`
  margin-bottom: 2rem;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  label {
    font-size: 1.5rem;
    text-transform: capitalize;
    margin-right: 1rem;
    line-height: 1rem;
  }

  ${ToggleWrapperDiv} {
    border: 1px solid ${({ theme, disabled }) => (disabled ? theme.palette.lightGrey : theme.palette.platinum)};
    width: 5rem;
    height: 1.5rem;
  }
`;
