import React from 'react';
import { CONTENT_ITEM_PRESENTATION_TYPE } from '@pushologies/common/constants/content-item';
import { useStoreActions, useStoreState } from '~store/hooks';
import { Select, SelectOption } from '~components/select';
import { Button } from '~components/button';
import S3Icon from '~images/s3.svg';
import { ApiContentItem } from '~api/content-items/types';
import { ProviderNavDiv, ProviderViewerDiv, ProviderConfigDiv } from '../styles';
import { ProviderItemList } from './item-list';
import { ImportsPayload, ImportModal } from './import-modal';

interface Props {
  presentation: CONTENT_ITEM_PRESENTATION_TYPE[];
  onImport(items: ApiContentItem[]): void;
  testId?: string;
}

export const ProviderViewer: React.FC<Props> = (props) => {
  const [importing, setImporting] = React.useState(false);
  const [importsPayload, setImportPayload] = React.useState<ImportsPayload>();
  const { activeProviderId, providers, selectedItemReferences } = useStoreState((state) => state.contentItems);
  const { setModel, importProviderItems, clearSelectedItemReference } = useStoreActions((state) => state.contentItems);
  const { addNotification } = useStoreActions((state) => state.notificationWidget);
  const providerOptions: SelectOption<string>[] = providers.map((provider) => ({
    name: provider.name,
    value: provider._id,
    icon: S3Icon
  }));

  const handleProviderChange = (options: SelectOption[]) => {
    setModel({ activeProviderId: options[0].value });
  };

  const handleItemsImport = () => {
    setImporting(true);
    importProviderItems({
      items: selectedItemReferences.map((itemReference) => ({
        itemReference,
        presentation: props.presentation
      })),
      onSuccess(imports) {
        clearSelectedItemReference();
        setImporting(false);
        const successfulImports = imports.filter((item) => item.status === 201);
        props.onImport(successfulImports.map(({ item }) => item));
        if (successfulImports.length === selectedItemReferences.length) {
          return addNotification({
            type: 'success',
            message: 'Successfully uploaded items'
          });
        }
        setImportPayload({
          failed: imports.filter((item) => ![201, 409].includes(item.status)),
          existing: imports.filter((item) => item.status === 409),
          success: successfulImports
        });
      },
      onError(message) {
        console.error(message);
        setImporting(false);
      }
    });
  };

  const resetImportsPayload = () => {
    setImportPayload(null);
    setModel({ selectedItemReferences: [] });
  };

  return (
    <ProviderViewerDiv data-testid={props.testId}>
      <ProviderConfigDiv>
        <Select
          id="activeProvider"
          value={providerOptions.filter(({ value }) => value === activeProviderId)}
          options={providerOptions}
          onChange={handleProviderChange}
        />
        <Button
          onClick={handleItemsImport}
          className="importBtn"
          disabled={!selectedItemReferences.length}
          loading={importing}
        >
          Import
        </Button>
      </ProviderConfigDiv>
      <ProviderNavDiv>
        <ProviderItemList />
      </ProviderNavDiv>
      <ImportModal
        existing={importsPayload?.existing}
        failed={importsPayload?.failed}
        success={importsPayload?.success}
        onHide={resetImportsPayload}
        presentation={props.presentation}
      />
    </ProviderViewerDiv>
  );
};
