import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { ApiContentProviderItem } from '~api/content-items/types';
import { TabsSection } from '~components/file-viewers/tabs/styles';
import { StyledDropdown } from '~components/select/styles';
import { Button } from '~components/button';

export const ContentItemsViewerSection = styled.section`
  position: relative;
  display: block;
  padding: 0.5rem;
  border: 1px solid ${({ theme }) => theme.palette.lightGrey};

  ${TabsSection} {
    box-shadow: unset;

    nav.nav {
      margin-bottom: 0.5rem;
    }
  }
`;

export const ProviderViewerDiv = styled.div`
  position: relative;
  display: block;
  border-bottom: none;
  border-top: none;
`;

export const ProviderConfigDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;

  ${StyledDropdown} {
    flex-grow: 2;
  }

  .importBtn {
    margin-left: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 0.25rem;
  }
`;

export const ProviderNavDiv = styled.div`
  background: ${({ theme }) => theme.palette.faintGrey};
  padding: 0.5rem;
`;

export const ProviderItemListDiv = styled.div`
  position: relative;
  max-height: 20rem;
  overflow-y: scroll;
  padding: 0;

  div.loadingPlaceholder {
    height: 5rem;
  }

  /* Hide scrollbar */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }

  .scrollEndLoader {
    position: relative;
    width: 100%;
    height: 2rem;
    padding: 0.25rem 0;
  }
`;

interface ProviderItemDivProps {
  $type: ApiContentProviderItem['type'];
}

export const ListRowDiv = styled.div<ProviderItemDivProps>`
  position: relative;
  display: flex;
  width: 100%;
  background: ${({ $type }) => ($type === 'directory' ? 'white' : 'transparent')};
  padding: 0.6rem 1rem;
  border-bottom: solid 1px ${({ theme }) => theme.palette.faintGrey};

  .type {
    display: flex;
    width: 2rem;
    margin-right: 1rem;

    svg {
      width: 100%;
      path {
        fill: ${({ theme }) => theme.palette.platinum};
      }
    }
  }

  .metadata {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 2;

    p {
      margin: 0;
      font-size: 0.9rem;
      line-height: 1rem;
      font-family: EavesBold;

      span {
        display: block;
        font-family: EavesRegular;
        font-size: 0.7rem;
      }
    }
  }

  .action {
    display: flex;

    .check {
      position: relative;
    }

    .expand {
      padding: 0.4rem;
      cursor: pointer;

      svg {
        width: 2rem;
        height: 1.5rem;
        fill: ${({ theme }) => theme.palette.textGrey};
        transition: transform 0.2s;
      }
    }
  }
`;

export const BreadcrumbDiv = styled.div`
  background-color: #fff;
  margin-bottom: 1rem;
  padding: 0.25rem 0.5rem;

  .clickable {
    color: ${({ theme }) => theme.palette.blue} !important;
    cursor: pointer;

    &:hover {
      text-decoration: underline !important;
    }
  }

  .disableClick {
    pointer-events: none;
  }
`;

export const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 600px;

    .modal-content {
      padding: 1rem 1rem 2rem 1rem;

      h3 {
        font-family: EavesBold;
        text-transform: uppercase;
        color: ${({ theme }) => theme.palette.darkGrey};
        font-size: 1.1rem;
        margin: 1rem 0 0;
      }

      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: EavesRegular;
        color: ${({ theme }) => theme.palette.platinum};
      }
    }
  }
`;

export const StyledButton = styled(Button)`
  font-size: 0.75rem;
  padding: 0.1rem 0.75rem;
`;
